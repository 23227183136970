import React from "react";

/**
 * The configuration for each insurance type and what fields they have
 * Ideally we want this to be somewhere in configs or database
 * so we can update this without doing a deployment
 * But for now, it is here...
 */

export const GL = {
  displayName: "COMMERCIAL GENERAL LIABILITY",
  selectableCoverage: ["COMMERCIAL GENERAL LIABILITY", "CUSTOM", "CUSTOM"],
  insuranceForm: true,
  aggregateAppliesPer: ['POLICY', 'PROJECT', 'LOC', 'OTHER'],
  additionalInsured: true,
  subrogationWaiver: true,
  limits: [
    "EACH OCCURRENCE",
    "DAMAGE TO RENTED PREMISES (Ea occurrence)",
    "MED EXP (Any one person)",
    "PERSONAL & ADV INJURY",
    "GENERAL AGGREGATE",
    "PRODUCTS - COMP/OP AGG"
  ]
}

export const AU = {
  displayName: "AUTOMOBILE LIABILITY",
  coveredProperty: [
    'ANY AUTO',
    'ALL OWNED AUTOS', 'SCHEDULED AUTOS',
    'HIRED AUTOS', 'NON-OWNED AUTOS',
    'OTHER',
  ],
  additionalInsured: true,
  subrogationWaiver: true,
  limits: [
    "COMBINED SINGLE LIMIT (Ea accident)",
    "BODILY INJURY (Per person)",
    "BODILY INJURY (Per accident)",
    "PROPERTY DAMAGE (Per accident)",
  ]
}

export const UMB = {
  displayName: "UMBRELLA LIAB",
  selectableCoverage: ["UMBRELLA LIAB", "EXCESS LIAB"],
  insuranceForm: true,
  additionalInsured: true,
  subrogationWaiver: true,
  deductible: true,
  retention: true,
  limits: [
    "EACH OCCURRENCE",
    "AGGREGATE"
  ]
}

export const XL = {
  displayName: "EXCESS LIABILITY",
  insuranceForm: true,
  additionalInsured: true,
  subrogationWaiver: true,
  deductible: true,
  retention: true,
  limits: [
    "EACH OCCURRENCE",
    "AGGREGATE"
  ]
}

export const WC = {
  displayName: "WORKERS COMPENSATION AND EMPLOYERS' LIABILITY",
  subrogationWaiver: true,
  exclusion: true,
  limitPerStatute: true,
  limits: [
    "E.L. EACH ACCIDENT",
    "E.L. DISEASE - EA EMPLOYEE",
    "E.L. DISEASE - POLICY LIMIT"
  ]
}

export const BASIC = {
  additionalInsured: true,
  subrogationWaiver: true,
  limits: [
    "EACH OCCURRENCE",
    "AGGREGATE"
  ]
}

export const PL = {
  displayName: "PROFESSIONAL LIABILITY",
  ...BASIC
}

export const CY = {
  displayName: "CYBER LIABILITY",
  ...BASIC
}

export const CUSTOM = {
  ...BASIC,
  insuranceForm: true,
  deductible: true,
  retention: true,
}

export default {
  GL,
  AU,
  UMB,
  XL,
  WC,
  PL,
  CY,
  CUSTOM,
}

export const non_custom_insurance_types = [
  "GL",
  "AU",
  "UMB",
  "WC",
]

export const coverageFullName = {
  GL: "GENERAL LIABILITY",
  AU: "AUTO LIABILITY",
  UMB: "UMBRELLA",
  WC: "WORKERS COMPENSATION"
}

export const COVERAGE_PROPERTIES  = {
  PER_STATUTE: {
    label: "PER STATUTE", 
    name: "PER STATUTE"
  },
  limitPerStatute: {
    label: "PER STATUTE", 
    name: "PER STATUTE"
  },
  CLAIMS_MADE: {
    label: "CLAIMS_MADE", 
    name: "CLAIMS_MADE"
  },
  OCCUR: {
    label: "OCCUR", 
    name: "OCCUR"
  },
  additionalInsured: {
    label: "ADDL INSD", 
    name: "additionalInsured"
  },
  subrogationWaiver: {
    label: "SUBR WVD", 
    name: "subrogationWaiver"
  },
  exclusion: {
    label: "EXCLUSION", 
    name: "exclusion", 
  },
}

export const CERTIFICATE_PROPERTIES = {
  insured: {
    label: "INSURED",
    name: "insured"
  },
  certificateHolder: {
    label: "CERTIFICATE HOLDER",
    name: "certificateHolder"
  },
}
