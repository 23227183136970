import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Stack,
  Box,
  Button,
  Divider,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Link,
  TextField,
  Select,
  MenuItem,
  IconButton,
  InputAdornment,
  Input,
} from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import CloseIcon from "@mui/icons-material/Close";
import { Loader, Dimmer } from "semantic-ui-react";
import { ReactMultiEmail, isEmail } from "react-multi-email";

import { client_mode, classification_id } from "constants/client_properties";
import { SYSTEM, AGENCY, getVendorLabel, getProjectLabel } from "constants/organization_types";
import DisplayProjectCoverages from "components/AddVendorWizard/DisplayProjectCoverages";
import { SideMenu } from "components/shared/SideMenuContainer";
import LoaderButton from "components/shared/LoaderButton";
import Snackbar from "components/shared/Snackbar";
import CertificateHeader from "./CertificateHeader";
import CertificateInfoSection from "./CertificateInfoSection";
import CertificateFilesSection from "./CertificateFilesSection";
import CertificateFileDisplay from "./CertificateFileDisplay";
import CertificateOtherInfoSection from "./CertificateOtherInfoSection";
import CertificateCoveragesSection from "./CertificateCoveragesSection";
import CertificateRequirementSection from "./CertificateRequirementSection";
import Tooltips from "components/shared/Tooltips";
import { SubtitleInline } from "components/shared/Typography";

import { useDispatch, useSelector } from "react-redux";
import { vendorActions, vendorState, certificateState } from "redux/vendorSlice";
import { organizationState } from "redux/organizationSlice";
import { loggedInUserState } from "redux/userSlice";
import { clientClassificationsState } from "redux/clientSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getCertificateFiles } from "services/appResources";
import { createFileNameUnique } from "utils/formUtils";
import "./certificate-details.scss";

import useScanData from "hooks/useScanData";
import useCertificateActivities from "hooks/useCertificateActivities";
import useVendorActivities from "hooks/useVendorActivities";
import { getNavigationPath, useNavPath, NAVIGATED_FROM } from "constants/routes";
import { validate } from "utils/certValidationUtils";
import { NavigateNextRounded } from "@mui/icons-material";

const menuItems = [
  {
    key: "overview",
    label: "Certificate Overview",
  },
  {
    key: "coverages",
    label: "Coverages",
  },
  {
    key: "documents",
    label: "Documents",
  },
  {
    key: "associations",
    label: "Associations",
  },
];

const scanResultItems = [
  {
    key: "overview",
    label: "Certificate Overview",
  },
  {
    key: "coverages",
    label: "Coverages",
  },
];

const scanStageDisplayText = {
  RECEIVED: "file received",
  SCANNING: "scan started",
  PROCESSING: "saving scan result",
  COMPLETE: "scan completed",
};

export default function CertificateDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const { clientId, certificateId, vendorId } = useParams();
  const dispatch = useDispatch();
  const { isAdmin } = useSelector(loggedInUserState);
  const { vendors } = useSelector(vendorState);
  const certificate = useSelector(certificateState(certificateId));
  const [referrer, setReferrer] = useState(location?.state?.referrer);
  const clientVendorID = certificate?.clientVendorID ? certificate?.clientVendorID : vendorId;
  const [clientVendor, setClientVendor] = useState();
  const [certificateData, setCertificateData] = useState({});
  const [certificateFiles, setCertificateFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const [useClientDefaultProject, setUseClientDefaultProject] = useState(false);
  const [selectedProject, setSelectedProject] = useState();
  const [fileSelected, setFileSelected] = useState(false);
  const [selectedEmailAddresses, setSelectedEmailAddresses] = useState([]);
  const { selectedClient, userOrgType, selectedAgency } = useSelector(organizationState);
  const projects = useSelector((state) => state.client.projects[selectedClient?.clientID]);
  const NAV_PATH = useNavPath(userOrgType);
  const clientMode = selectedClient?.mode;
  const hasEditPrivileges = isAdmin; //useMemo(() => [SYSTEM, AGENCY].includes(userOrgType), [userOrgType])
  const vendorLabel = getVendorLabel(selectedClient);
  const projectLabel = getProjectLabel(selectedClient);

  const formRef = useRef();
  const activeItem = String(location.hash).replace("#/", "");
  const canSave = ["overview", "coverages", "documents"].includes(activeItem);
  const vendorClassifications = clientMode && clientVendor?.[clientMode === client_mode.category ? "categories" : "projects"];
  const clientClassifications = useSelector(clientClassificationsState);
  const { scanData, getScanData, discardScanData } = useScanData(certificateId, true);
  const hasPending = scanData?.status === "PENDING";
  const hasCompleted = scanData?.status === "COMPLETED";
  const hasFailed = scanData?.status === "FAILED";
  const hasSubmitted = scanData?.status === "SUBMITTED";
  const [viewCompleted, setViewCompleted] = useState(false);
  const [cancelResultAlert, setCancelResultAlert] = useState(false);
  const [showPending, setShowPending] = useState(false);
  const [showSendRejectionEmail, setShowRejectionEmail] = useState(false);
  const [rejectionEmailErrors, setRejectionEmailErrors] = useState([]);
  const [rejectionEmailContent, setRejectionEmailContent] = useState("");
  const [initialCoverageRequirements, setInitialCoverageRequirements] = useState();
  const [showProjectCoverages, setShowProjectCoverages] = useState(false)
  const [certificateActivities, addCertificateActivity, addCertificateProjectActivity] = useCertificateActivities(certificate);
  const [, addVendorActivity] = useVendorActivities(clientVendor);

  const hasVendorDisabledNotifications = clientVendor?.notificationStatus === "DISABLED";

  useEffect(() => {
    if (hasCompleted) {
      setShowPending(true);
      if (location.hash !== "#/overview" || location.hash !== "#/coverages") {
        navigate(location.pathname + `#/overview`);
      }
    } else if (hasFailed || hasSubmitted) {
      setShowPending(false);
    }
  }, [hasCompleted, hasFailed, hasSubmitted]);

  const vendorProjects = useMemo(() => {
    if (clientVendor && clientVendor?.projects?.items.length && projects?.length) {
      let selectedVendorProjects;
      if (useClientDefaultProject) {
        selectedVendorProjects = [projects.find((project) => project.isDefault)];
        setSelectedProject(selectedVendorProjects[0]?.id);
      } else {
        selectedVendorProjects = clientVendor.projects.items.map((vendorProject) => ({
          ...(projects.find((project) => vendorProject.projectID === project.id) || {}),
        }));
        if (!selectedProject) {
          setSelectedProject(selectedVendorProjects?.find((project) => project.isDefault)?.id || selectedVendorProjects[0]?.id);
        }
      }
      setCertificateData({
        ...certificateData,
        projectID: selectedProject,
      });
      return selectedVendorProjects;
    }
  }, [clientVendor?.projects?.items, projects?.items?.length, selectedProject]);

  useEffect(() => {
    navigate(location.pathname + `#/${menuItems[0].key}`);

    if (clientVendorID) {
      const clientVendor = vendors?.find((oneVendor) => oneVendor.vendorID === clientVendorID || oneVendor.id === clientVendorID);
      setClientVendor(clientVendor);
    }
    setUseClientDefaultProject(selectedClient?.useDefaultProject);
  }, [clientVendorID, vendors]);

  const [initialLoad, setInitialLoad] = useState(false);
  async function loadInitialData() {
    await dispatch(vendorActions.getCertificate(certificateId));
    setInitialLoad(true);
  }

  useEffect(() => {
    if (certificateId && certificateId !== "create") {
      loadInitialData();
    }
  }, [certificateId]);

  // Set client Vendor if navigating from the Certificates list
  useEffect(() => {
    if (initialLoad && certificate) {
      setCertificateData({
        ...certificate,
      });

      if (certificate.initialCoverageRequirements && typeof certificate.initialCoverageRequirements === "string") {
        setInitialCoverageRequirements(JSON.parse(certificate.initialCoverageRequirements));
      }

      if (certificate?.acord25File || certificate.files?.length) {
        setFileSelected(certificate?.acord25File || certificate.files[0]);
        let files = [];
        certificate?.acord25File && files.push({ name: certificate?.acord25File, isAcord25: true });
        certificate.files?.length && (files = files.concat(certificate.files));
        getCertificateFiles(certificate.id, files, (result) => setCertificateFiles(result));
      } else {
        setCertificateFiles([]);
      }
    }
  }, [certificate, initialLoad]);

  useEffect(() => {
    if (certificateFiles?.length && certificateFiles[certificateFiles.length - 1]?.isNew) {
      setFileSelected(certificateFiles[certificateFiles.length - 1].name);
    }
  }, [certificateFiles]);

  useEffect(() => {
    if (showSendRejectionEmail) {
      const certificateProject = clientClassifications?.find((project) => project.id === certificateData.projectID);
      const result = validate(certificateData, certificateProject?.requiredCoverages);
      setRejectionEmailErrors([...result?.validationErrors]);
      const additionalEmailAddresses = [certificateData?.producerEmail || ""];
      if (selectedClient?.usePrimaryNotificationEmailAddress && selectedClient?.primaryNotificationEmailAddress) {
        additionalEmailAddresses.push(selectedClient?.primaryNotificationEmailAddress);
      }
      setSelectedEmailAddresses(additionalEmailAddresses);
    } else {
      setRejectionEmailErrors([]);
      setRejectionEmailContent("");
    }
  }, [showSendRejectionEmail]);

  async function confirmSendRejectionEmail() {
    let errors = "";
    rejectionEmailErrors.forEach((validationError, index) => (errors += `${index + 1}. ${validationError}\n`));
    if (hasVendorDisabledNotifications) {
      setShowRejectionEmail(false);
      return;
    }
    const createCertResult = await dispatch(
      vendorActions.createRequestCertificate({
        clientVendorID: clientVendor.id,
        clientID: selectedClient.clientID,
        ...(certificateData?.projectID ? { projectID: certificateData?.projectID } : {}),
        ...(certificateData?.initialCoverageRequirements && { initialCoverageRequirements: certificateData?.initialCoverageRequirements }),
      })
    );
    const uploadLink =
      !!selectedClient && !!clientVendor && createCertResult?.payload?.certificateId
        ? window.location.origin + `/vendor/upload/${selectedClient.id}/${clientVendor.vendor.id}/${createCertResult.payload.certificateId}`
        : "";
    const result = await dispatch(
      vendorActions.sendEmailToVendor({
        subject: "Your Certificate Is Not Approved",
        content:
          `Your certificate is not approved for the following reasons:\n` +
          (!!errors ? `${errors}\n\n` : "") +
          (rejectionEmailContent ? `${rejectionEmailContent}.\n\n` : "") +
          (uploadLink ? `Please use <a href=${uploadLink}>this link</a> to upload a new certificate.` : ""),
        vendorName: clientVendor.vendor.name,
        signature: `${selectedClient.name}`,
        recipients: [clientVendor.vendor.email, ...selectedEmailAddresses],
        action: "Custom",
      })
    );
    if (result?.payload?.success) {
      setMessage({
        severity: "success",
        text: `Successfully sent email to `,
      });
      if (uploadLink) {
        addCertificateActivity({
          ...certificateData,
          requestedNewValidCertificateURL: `${window.location.origin}/client/${selectedClient.id}/vendors/${clientVendor.vendor.id}/certificates/${createCertResult?.payload?.certificateId}`,
        });
      }
    }
    setShowRejectionEmail(false);
  }

  function getCertificateStatus(status) {
    setCertificateData({
      ...certificateData,
      status,
    });
  }

  async function onDeleteCertificate() {
    if (!isAdmin) {
      setMessage({
        severity: "error",
        text: `You don't have permission to delete certificate`,
      });
      return;
    }

    const result = await dispatch(vendorActions.deleteCertificate(certificateData));
    if (result?.payload.success) {
      await addVendorActivity({
        action: "EMAIL_SEND",
        clientVendorID: clientVendorID,
        vendor: {
          message: "DELETED CERTIFICATE",
          details: {
            ...certificate,
          },
        },
      });
      setMessage({
        severity: "success",
        text: `Successfully deleted certificate.`,
      });
      setTimeout(() => {
        navBack();
      }, 1000);
    } else {
      setMessage({
        severity: "error",
        text: `Unable to delete certificate.`,
      });
    }
    return result;
  }

  async function handleCertificateSubmit(updates = {}) {
    setLoading(true);
    const defaultProjectID = clientClassifications.find((clientClassification) => clientClassification.isDefault)?.id;
    const projectID = updates.projectID || certificateData.projectID || selectedProject;
    const newCertificateData = {
      ...certificateData,
      ...updates,
      ...(clientMode === client_mode.project && projectID ? { projectID } : {}),
      clientVendorID: certificateData.clientVendorID || clientVendor?.id,
      clientID: certificateData.clientID || clientVendor?.clientID,
      coverages: {
        items: formRef.current?.getCoverageData() || [],
      },
    };
    const isRejected = certificateData.id && certificate?.status !== "NOT_APPROVED" && newCertificateData?.status === "NOT_APPROVED";
    const action = certificateData.id ? "update" : "create";
    let createInitialCoverageRequirements;
    if (action === "create") {
      if (selectedProject) {
        createInitialCoverageRequirements = projects.find(project => project.id === selectedProject)?.requiredCoverages
      }
      if (createInitialCoverageRequirements) {
        createInitialCoverageRequirements = { projectID: selectedProject, clientID: selectedClient.clientID, coverages: createInitialCoverageRequirements}
        createInitialCoverageRequirements = JSON.stringify(createInitialCoverageRequirements)
        newCertificateData.initialCoverageRequirements = createInitialCoverageRequirements
      }
    }
    const result = await dispatch(
      vendorActions.createOrUpdateCertificate({
        certificateData: newCertificateData,
        updatedFiles: certificateFiles,
        coveragesToDelete: formRef.current?.getCoverageDataToDelete() || [],
      })
    );
    if (result?.payload?.success) {
      setMessage({
        severity: "success",
        text: `Successfully ${action}d certificate`,
      });
      if (!certificateData.id) {
        await setCertificateData({
          ...certificateData,
          id: result?.payload?.certificateID,
        });
        addCertificateActivity({ action: "CREATE", id: result?.payload?.certificateID });
      } else {
        addCertificateActivity(newCertificateData, certificateFiles);
      }
      if (certificateFiles?.length && certificateFiles.find((certFile) => certFile.isNew && certFile.isAcord25)) {
        const destination =
          referrer || getNavigationPath(NAV_PATH.CLIENT_VENDOR_DETAILS, { clientId: selectedClient.organizationID, vendorId: clientVendor?.vendor?.id });
        navigate(destination);
      } else if (action === "update" && isRejected && !hasVendorDisabledNotifications) {
        setShowRejectionEmail(true);
      }
    } else {
      setMessage({
        severity: "error",
        text: `Unable to ${action} certificate`,
      });
    }
    setLoading(false);
    return { success: result?.payload?.success };
  }
  function onCreateUpdatePathWithId(certificateID) {
    if (String(location.pathname).includes("create")) {
      navigate(String(location.pathname).replace("create", certificateID) + location.hash);
    }
  }
  function handleCertificateInfoUpdate(event) {
    const { name, value } = event.target;
    setCertificateData({
      ...certificateData,
      [name]: value,
    });
  }
  function handleCertificateCoverageUpdate({ id = null, name, value, index: updateIndex }) {
    const currentCoverages = [...(certificateData?.coverages?.items || [])];
    setCertificateData({
      ...certificateData,
      coverages: {
        items: currentCoverages.map((coverage, index) => {
          if (updateIndex === index) {
            return {
              ...coverage,
              [name]: value,
            };
          }
          return coverage;
        }),
      },
    });
  }

  function handleCertificateAddCoverage(defaultCoverage) {
    const coverageData = { ...defaultCoverage };
    delete coverageData.id;
    const updatedCoverages = [...(certificateData?.coverages?.items || []), coverageData];
    setCertificateData({
      ...certificateData,
      coverages: {
        items: updatedCoverages,
      },
    });
  }

  function handleEmailAddressesChange(emails) {
    setSelectedEmailAddresses(emails);
  }

  function handleEmailAddressesRemove(index) {
    setSelectedEmailAddresses([...selectedEmailAddresses.slice(0, index), ...selectedEmailAddresses.slice(index + 1)]);
  }

  const handleFileUpload = (e, newAcord25FileName) => {
    const file = e?.target?.files ? e?.target?.files[0] : e?.dataTransfer?.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (event) {
        const fileName = String(file.name).replaceAll(" ", "");
        const fileNameUnique = newAcord25FileName
          ? fileName
          : createFileNameUnique(
              String(file.name).replaceAll(" ", ""),
              certificateFiles.map((certificateFile) => certificateFile.name)
            );
        const newFiles = certificateFiles.map((file) => {
          if (newAcord25FileName && file.isAcord25) {
            if (!file.isNew) {
              return {
                ...file,
                isDeleted: true,
                isAcord25: true,
              };
            } else {
              return {
                ...file,
                isNew: false,
                isAcord25: true,
                isDeleted: true,
              };
            }
          }
          return { ...file };
        });

        setCertificateFiles([
          ...newFiles,
          {
            content: event.target.result,
            name: fileName,
            isNew: true,
            isAcord25: !!newAcord25FileName,
          },
        ]);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileDelete = (fileName) => {
    setCertificateFiles(
      certificateFiles.map((certificateFile) =>
        certificateFile.name === fileName ? { ...certificateFile, isDeleted: !certificateFile.isDeleted } : certificateFile
      )
    );
  };

  const onDiscardScanResult = async () => {
    if (scanData?.id) {
      setLoading(true);
      await discardScanData();
      // setViewCompleted(false)
      setCancelResultAlert(false);
      setLoading(false);
    }
  };

  const onSubmitScanResult = async () => {
    setLoading(true);
    await dispatch(vendorActions.submitScanResultToCertificate({ certificateData, scanData }));
    getScanData();
    // setViewCompleted(false)
    setLoading(false);
  };

  const CertificateActions = () =>
    canSave && (
      <LoaderButton
        variant={"contained"}
        onClick={() => handleCertificateSubmit()}
        disabled={loading || hasPending || !hasEditPrivileges || (certificateId === "create" && !certificateFiles?.length)}
        loading={loading ? loading.toString() : undefined}
        size="small"
        sx={{ ml: "auto", alignSelf: "center" }}
      >
        Save
      </LoaderButton>
    );

  const ScanResultActions = () => (
    <Box sx={{ ml: "auto" }}>
      <Button variant={"outlined"} size="small" sx={{ mr: 1 }} disabled={loading} onClick={() => setCancelResultAlert(true)}>
        Discard
      </Button>
      <LoaderButton variant={"contained"} size="small" loading={loading} onClick={onSubmitScanResult}>
        Confirm
      </LoaderButton>
    </Box>
  );

  function navBack() {
    const vendorsPath = navBackHelper(certificateId);
    navigate(referrer || vendorsPath);
  }

  function navBackHelper(certificateId) {
    if (certificateId === NAVIGATED_FROM.CERTIFICATES) {
      return getNavigationPath(NAV_PATH.CLIENT_CERTIFICATES, { clientId: clientId });
    } else if (certificateId === NAVIGATED_FROM.PROJECT) {
      return getNavigationPath(NAV_PATH.CLIENT_PROJECTS, { clientId: clientId });
    } else {
      return getNavigationPath(NAV_PATH.CLIENT_VENDOR_DETAILS, { clientId: clientId, vendorId: vendorId });
    }
  }

  function navBackHelperText(certificateId) {
    if (certificateId === NAVIGATED_FROM.CERTIFICATES) {
      return NAVIGATED_FROM.CERTIFICATES;
    } else if (certificateId === NAVIGATED_FROM.PROJECT) {
      return `${projectLabel}s`;
    } else {
      return `${vendorLabel} Detail`;
    }
  }

  return (
    <Box className="certificate-details-wrapper">
      {
        <Button onClick={() => navBack()}>
          <KeyboardArrowLeftIcon />
          Back to {navBackHelperText(vendorId)}
        </Button>
      }

      <Snackbar message={message} setMessage={setMessage} />

      {hasFailed && (
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <Typography variant="h5" as="span" pl={2}>
            There was an{" "}
            {
              <Tooltips.LightTooltip
                title={
                  <div>
                    <p>File Name: {scanData.acord25File}</p>
                    <p>Error: {JSON.stringify(scanData.scanError)}</p>
                  </div>
                }
              >
                <Link underline={"hover"}>error</Link>
              </Tooltips.LightTooltip>
            }{" "}
            with the last scan. Please try again.
          </Typography>
        </Box>
      )}

      {(hasPending || showPending) && (
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <Loader active inline indeterminate size="small" />
          <Typography variant="h5" as="span" pl={2}>
            {hasPending && `Scanning in progress for Acord25 file... ${(scanData?.scanStage && scanStageDisplayText[scanData.scanStage]) || ""}`}
            {hasCompleted && `Saving scan result to certificate...`}
          </Typography>
        </Box>
      )}

      {showSendRejectionEmail && (
        <Dialog open={showSendRejectionEmail} maxWidth={"xl"} fullWidth={true}>
          <DialogTitle>Send email notification to {vendorLabel}?</DialogTitle>
          <DialogContent>
            {!!rejectionEmailErrors.length && (
              <>
                <Typography variant="body1" sx={{ pb: 1 }}>
                  We found these errors for this certificate
                </Typography>
                <Stack flexDirection="column">
                  {rejectionEmailErrors.map((e, i) => (
                    <Input
                      id={`rejection-email-error-${i}`}
                      value={e}
                      onChange={(e) => {
                        const updatedRejectionErrors = [...rejectionEmailErrors]
                        updatedRejectionErrors[i] = e.target.value;
                        setRejectionEmailErrors(updatedRejectionErrors)
                      }}
                      startAdornment={`${i + 1}. `}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            variant="outlined"
                            onClick={() => {
                              const updatedRejectionErrors = [...rejectionEmailErrors];
                              updatedRejectionErrors.splice(i, 1);
                              setRejectionEmailErrors(updatedRejectionErrors);
                            }}
                            sx={{ float: "right" }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  ))}
                </Stack>
              </>
            )}

            <Box sx={{ mt: 2 }}>
              <SubtitleInline sx={{my: 1}}>Recipient(s)</SubtitleInline>
              <ReactMultiEmail
                placeholder="Other recipients"
                emails={selectedEmailAddresses}
                onChange={(_emails) => handleEmailAddressesChange(_emails)}
                validateEmail={(email) => isEmail(email)}
                getLabel={(email, index) => (
                  <div data-tag key={index}>
                    {email}
                    <span data-tag-handle onClick={() => handleEmailAddressesRemove(index)}>
                      ×
                    </span>
                  </div>
                )}
              />
              <Typography variant="body1" sx={{ pb: 1, mt: "1em !important" }}>
                {!!rejectionEmailErrors.length ? "Additional notes" : "Reason for not approved"}
              </Typography>
              <TextField multiline={true} value={rejectionEmailContent} fullWidth={true} onChange={(e) => setRejectionEmailContent(e.target.value)} />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowRejectionEmail(false)} disabled={loading}>
              Do Not Send Email
            </Button>
            <Button onClick={confirmSendRejectionEmail} disabled={loading || hasVendorDisabledNotifications}>
              Send
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {hasCompleted && (
        <Dialog open={cancelResultAlert}>
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogContent>
            <DialogContentText>This certificate will not be updated.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setCancelResultAlert(false)} disabled={loading}>
              Cancel
            </Button>
            <Button onClick={onDiscardScanResult} disabled={loading}>
              Confirm Discard Result
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {certificateId === "create" && (
        <>
          <Box sx={{ float: "right" }}>
            <CertificateActions />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", pl: 2 }}>
            {vendorProjects && (
              <>
                <SubtitleInline sx={{ mt: 2 }}>Select A {projectLabel} *</SubtitleInline>
                <Select
                  size="small"
                  sx={{ minWidth: 120, mb: 2 }}
                  defaultValue={selectedProject}
                  onChange={(e) => {
                    setSelectedProject(e.target.value);
                  }}
                >
                  {vendorProjects.map((vendorProject) => {
                    return (
                      <MenuItem key={"create-project-" + vendorProject.name} value={vendorProject.id}>
                        {vendorProject.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <Button onClick={() => setShowProjectCoverages(!showProjectCoverages)}>
                  {showProjectCoverages ? "Hide Coverage Requirements": "Show Coverage Requirements"}
                </Button>
                {selectedProject && vendorProjects.find((project) => project.id === selectedProject) && showProjectCoverages && (
                  <DisplayProjectCoverages
                    project={vendorProjects.find((project) => project.id === selectedProject)}
                    certificateHolder={selectedClient?.name}
                    projectLabel={projectLabel}
                  />
                )}
              </>
            )}
            <CertificateFileDisplay
              key={"cert-file-display"}
              {...{
                certificateFiles,
                fileSelected,
                enableDownload: true,
                handleFileUpload,
                disableUpload: false,
              }}
            />
          </Box>
        </>
      )}
      {certificateId !== "create" && (
        <Dimmer.Dimmable>
          <Dimmer active={hasPending} inverted={true} style={{ backgroundColor: "rgba(255, 255, 255, 0.4)" }} />
          <Stack direction="row" spacing={2} pb={4}>
            <Stack>
              {viewCompleted ? (
                <CertificateFileDisplay
                  key={"scan-file-display"}
                  {...{
                    certificateFiles: scanData?.files,
                    fileSelected: scanData?.acord25File,
                    enableDownload: false,
                    disableUpload: true,
                  }}
                />
              ) : (
                <CertificateFileDisplay
                  key={"cert-file-display"}
                  {...{
                    certificateFiles,
                    fileSelected,
                    enableDownload: true,
                    handleFileUpload,
                    disableUpload: false,
                    hasEditPrivileges,
                  }}
                />
              )}
            </Stack>
            <Divider orientation={"vertical"} flexItem={true} />
            <Stack sx={{ flexGrow: 1 }}>
              <Typography variant="h5" mr={2}>
                <Button
                  onClick={() => {
                    navigate(getNavigationPath(NAV_PATH.CLIENT_VENDOR_DETAILS, { clientId: clientId, vendorId: clientVendor?.vendor?.id }));
                  }}
                >
                  {clientVendor?.vendor?.name}
                </Button>
                <Box sx={{ float: "right" }}>{viewCompleted ? <ScanResultActions /> : <CertificateActions />}</Box>
              </Typography>
              <SideMenu
                items={viewCompleted ? scanResultItems : [...menuItems, ...(isAdmin ? [{ key: "other", label: "Other" }] : [])]}
                vertical={false}
                style={{ marginTop: 0 }}
                className={"major"}
              />
              {canSave &&
                (viewCompleted ? (
                  <CertificateHeader
                    key={"scan-header"}
                    {...{
                      status: certificateData?.status,
                      certificateDate: scanData?.certificateDate,
                      isEdit: false,
                    }}
                  />
                ) : (
                  <CertificateHeader
                    key={"cert-header"}
                    {...{
                      status: certificateData?.status,
                      getCertificateStatus,
                      certificateDate: certificateData?.certificateDate,
                      handleCertificateInfoUpdate,
                      isEdit: !hasPending && hasEditPrivileges,
                    }}
                  />
                ))}

              {activeItem === menuItems[0].key &&
                (viewCompleted ? (
                  <CertificateInfoSection
                    key={"scan-info-section"}
                    {...{
                      certificateData: scanData,
                      isEdit: false,
                      selectedClient,
                      selectedAgency,
                      vendor: clientVendor?.vendor,
                    }}
                    />
                  ) : (
                    <CertificateInfoSection
                    key={"cert-info-section"}
                    {...{
                      handleCertificateInfoUpdate,
                      certificateData,
                      isEdit: !hasPending && hasEditPrivileges,
                      selectedClient,
                      selectedAgency,
                      vendor: clientVendor?.vendor,
                    }}
                  />
                ))}
              {activeItem === menuItems[1].key && (
                <>
                  {viewCompleted ? (
                    <CertificateCoveragesSection
                      key={"scan-coverage-section"}
                      coverages={scanData?.coverages?.items}
                      // clientCoverages={selectedClient?.clientCoverages?.items}
                      clientMode={clientMode}
                      classifications={vendorClassifications}
                      classificationIDKey={classification_id[selectedClient?.mode]}
                      projectID={clientMode === client_mode.project && certificateData?.projectID}
                      isEdit={false}
                      initialCoverageRequirements={initialCoverageRequirements}
                      acord25FileDate={certificateData?.acord25FileDate}
                    />
                  ) : (
                    <CertificateCoveragesSection
                      key={"cert-coverage-section"}
                      ref={formRef}
                      coverages={certificateData?.coverages?.items}
                      // clientCoverages={selectedClient?.clientCoverages?.items}
                      clientMode={clientMode}
                      // onUpdate={handleCertificateCoverageUpdate}
                      onAdd={handleCertificateAddCoverage}
                      classifications={vendorClassifications}
                      classificationIDKey={classification_id[selectedClient?.mode]}
                      projectID={clientMode === client_mode.project && certificateData?.projectID}
                      isEdit={!hasPending && hasEditPrivileges}
                      initialCoverageRequirements={initialCoverageRequirements}
                      acord25FileDate={certificateData?.acord25FileDate}
                    />
                  )}
                </>
              )}
              {activeItem === menuItems[2].key && !viewCompleted && (
                <CertificateFilesSection
                  {...{
                    vendor: clientVendor?.vendor,
                    acord25FileName: certificateData?.acord25File,
                    certificateFiles,
                    handleFileUpload,
                    handleFileDelete,
                    setFileSelected,
                    showVendorLink: true,
                    disableUpload: hasPending || !hasEditPrivileges,
                    vendorLabel: vendorLabel,
                  }}
                />
              )}
              {activeItem === menuItems[3].key && !viewCompleted && (
                <CertificateRequirementSection
                  {...{
                    certificateID: certificateData?.id,
                    clientVendorID,
                    clientMode,
                    handleCertificateSubmit,
                    // clientCoverages: selectedClient?.clientCoverages?.items,
                    vendorClassifications: vendorClassifications?.items,
                    projectID: clientMode === client_mode.project && certificateData?.projectID,
                    onCreateUpdatePathWithId,
                    addCertificateProjectActivity,
                    hasEditPrivileges,
                    projectLabel: projectLabel,
                  }}
                />
              )}
              {activeItem === "other" && !viewCompleted && (
                <CertificateOtherInfoSection
                  {...{
                    certificateData,
                    handleCertificateSubmit,
                    loading,
                    certificateActivities,
                  }}
                  onDeleteCallback={onDeleteCertificate}
                />
              )}
            </Stack>
          </Stack>
        </Dimmer.Dimmable>
      )}
    </Box>
  );
}
