import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getOrganizationActivities, logOrganizationActivity } from "services/activity";
import { loggedInUserState } from "redux/userSlice";

const fieldsToCompare = [
  "name",
  "vendorLabel",
  "projectLabel",
  "clientLabel",
  "certificateHolder",
  "user_enabled",
  "user_disabled",
  "user_deleted",
  "user_invited",
  "templates",
  "clientCoverages",
  "logo",
  "useDefaultProject",
  "usePrimaryNotificationEmailAddress",
  "primaryNotificationEmailAddress",
  "tableRowsPerPage",
  "adminName",
  "adminEmail",
  "phone",
  "city",
  "state",
  "website",
  "zip",
];

export default function useOrganizationActivities(organization) {
  const [originalData, setOriginalData] = useState({});
  const [organizationActivities, setOrganizationActivities] = useState([]);
  const { loggedInUser } = useSelector(loggedInUserState);
  let userFullName = loggedInUser?.email;
  if (loggedInUser?.firstName || loggedInUser?.lastName) {
    userFullName = `${loggedInUser.firstName}${loggedInUser.firstName ? " " : ""}${loggedInUser.lastName}`;
  }

  const loadOrganizationActivities = async function (id) {
    const result = await getOrganizationActivities(organization?.id || id);
    setOrganizationActivities(result);
  };

  const addOrganizationActivity = async function (organizationData = {}, action = "EDIT") {
    const originalOrganizationInfo = originalData || {};
    const updates = {};
    Object.keys(organizationData).forEach((key) => {
      if (
        (organizationData[key] &&
        String(originalOrganizationInfo[key]) !== String(organizationData[key]))) {
        if (key === "templates") {
          const originalOrganizationTemplates = JSON.parse(originalOrganizationInfo[key]);
          const latestOrganizationTemplates = JSON.parse(organizationData[key]);
          Object.entries(latestOrganizationTemplates).forEach(([templateName, templateValue]) => {
            if (templateValue?.subject !== originalOrganizationTemplates[templateName]?.subject) {
              updates[key] = {
                ...updates[key],
                [templateName]: {
                  subject: {
                    prev: originalOrganizationTemplates[templateName]?.subject,
                    next: templateValue?.subject,
                  },
                },
              };
            }
            if (templateValue?.content !== originalOrganizationTemplates[templateName]?.content) {
              updates[key] = {
                ...updates[key],
                [templateName]: {
                  ...(updates[key]?.[templateName] || {}),
                  content: {
                    prev: originalOrganizationTemplates[templateName]?.content,
                    next: templateValue?.content,
                  },
                },
              };
            }
          });
        } else if (key === "clientCoverages") {
          updates[key] = [];
          const updatedCoverages = [...organizationData[key]];
          const originalCoverages = originalOrganizationInfo[key].items;
          originalCoverages.forEach((coverage) => {
            const checkIndex = updatedCoverages.findIndex((el) => el.id === coverage.id);
            //handle deleted coverage
            if (checkIndex < 0) {
              updates[key].push({
                [coverage.name ? coverage.name : coverage.insuranceType]: { prev: { name: coverage.name, insuranceType: coverage.insuranceType }, next: null },
              });
              return;
            }
            //handle coverage updates
            const differences = { prev: {}, next: {} };
            for (const propertyKey in coverage) {
              if (String(coverage[propertyKey]) !== String(updatedCoverages[checkIndex][propertyKey])) {
                differences.prev[propertyKey] = coverage[propertyKey];
                differences.next[propertyKey] = updatedCoverages[checkIndex][propertyKey];
              }
            }
            if (Object.keys(differences.next).length || Object.keys(differences.prev).length) {
              updates[key].push({ [coverage.name ? coverage.name : coverage.insuranceType]: differences });
            }
            //remove checked coverage from updated coverages
            updatedCoverages.splice(checkIndex, 1);
          });
          //handle created coverages
          if (updatedCoverages.length) {
            updatedCoverages.forEach((coverage) => {
              updates[key].push({
                [coverage.name ? coverage.name : coverage.insuranceType]: { prev: null, next: { name: coverage.name || coverage.insuranceType } },
              });
            });
          }
        } else {
          updates[key] = { [key]: { prev: originalOrganizationInfo[key], next: organizationData[key] } };
        }
      }
    });

    if (Object.keys(updates).length) {
      await logOrganizationActivity({
        organizationID: organization.id,
        userFullName,
        userID: loggedInUser.id,
        action: "EDIT",
        field: JSON.stringify(updates),
      });
      loadOrganizationActivities();
    }
  };

  useEffect(() => {
    if (organization?.id) {
      setOriginalData(organization);
      loadOrganizationActivities();
    }
  }, [organization]);

  return [organizationActivities, addOrganizationActivity];
}
