import React, {forwardRef, useEffect, useMemo, useState } from "react";
import {
  FormHelperText,
  Typography,
  Box,
  FormControlLabel,
  FormControl,
  Checkbox,
  Stack,
  FormGroup,
  TextField,
  InputAdornment,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  Divider,
  Button
} from "@mui/material";
import DeleteForever from "@mui/icons-material/DeleteForever"
import {formatCurrencyInt,removeFormat} from "utils/formUtils";
import {isExpired, usToAWSDate, getDateFromDateString, isLaterDate, awsDateToUS, isExpiringIn90Days, getExpirationDays} from "utils/dateUtils";
import {SubtitleInline} from "components/shared/Typography";
import CoverageLineItemEdit from "components/shared/CoverageForm/CoverageLineItemEdit";
import CoverageLineItem from "components/shared/CoverageForm/CoverageLineItem";
import {client_mode} from "constants/client_properties";
import DatePicker from "react-datepicker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"
import { Warning } from "@mui/icons-material";
import CertificateCoverageOverrideModal from "./CertificateCoverageOverrideModal";
import  {COVERAGE_PROPERTIES} from "constants/insurance_types";


export default function CertificateCoverageTypeSection (props) {

  const {
    index,
    isCustom,
    isEdit = false,
    onUpdate = () => { },
    onDeleteTab = () => { },
    coverageTypeConfig = {},
    coverageRequirement = null,
    clientMode = client_mode.category,
    referenceValues = {},
  } = props

  const customDatePickerInput = ({ value, onClick, error, name, index: dateIndex }, ref) => (
    <TextField
      name={name}
      defaultValue={value}
      onClick={onClick}
      ref={ref}
      className={error ? "coverages-validation-error" : null}
      color="error"
      variant={"outlined"}
      size="small"
      onBlur={
        (event) => {
          if (!/^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d{2}$/.test(event.target.value)) {
            event.target.value = value
            return
          }
          onDateUpdate(name, event.target.value, dateIndex)
        }}
    />
  )
  const PolicyEffInput = forwardRef(customDatePickerInput)
  const PolicyExpInput = forwardRef(customDatePickerInput)
  const [displayOverrideSection, setDisplayOverrideSection] = useState(false);
  const [numberOfCoverageOverrides, setNumberOfCoverageOverrides] = useState(0);
  
  
  let limits = coverageRequirement?.limits || {}
  if (typeof limits === 'string') {
    limits = JSON.parse(limits)
  }
  
  let overrides = coverageRequirement?.overrides || {}
  if (typeof overrides === 'string') {
    overrides = JSON.parse(overrides)
  }
  const coverageOverrides = useMemo(()=>({
    ...(coverageTypeConfig?.exclusion !== undefined &&
      referenceValues?.exclusion &&
      String(referenceValues?.exclusion) !== String(coverageRequirement?.exclusion) && { EXCLUSION: false }),
    ...overrides,
  }), [coverageTypeConfig?.exclusion, referenceValues?.exclusion, coverageRequirement?.exclusion, overrides])
  
  let referenceLimits = referenceValues?.limits || {}
  if (typeof referenceLimits === 'string') {
    referenceLimits = JSON.parse(referenceLimits)
  }

  useEffect(() => {
    const numberOfOverrides = Object.keys(coverageOverrides).length;
    setNumberOfCoverageOverrides(numberOfOverrides);
    if (numberOfOverrides === 0) {
      setDisplayOverrideSection(false)
    }
  },[coverageOverrides])

  function onOverride () {
    setDisplayOverrideSection(true);
  }

  function onCancelOverride () {
    setDisplayOverrideSection(false);
  }
  
  function onCoverageOverride (updatedOverrides = {}) {
    onUpdate({
      id: coverageRequirement.id,
      name: "overrides",
      value: {
        ...updatedOverrides
      },
      index
    })
    setDisplayOverrideSection(false);
  }

  function onInsurerLetterUpdate (letter, letterIndex) {
    const insurerLetterArray = [...(coverageRequirement?.insurerLetter || [""])]
    insurerLetterArray[letterIndex] = letter
    // if(letterIndex === insurerLetterArray.length - 1) {
    //   insurerLetterArray.push("")
    // }
    onUpdate({
      id: coverageRequirement.id,
      name: "insurerLetter",
      value: insurerLetterArray,
      index
    })
  }

  function onFieldUpdate (event) {
    let { name, value } = event.target
    if (event.target.type === 'checkbox') {
      value = event.target.checked
    }
    
    delete coverageOverrides[COVERAGE_PROPERTIES[event.target.name]?.label];
    onUpdate({
      id: coverageRequirement.id,
      name,
      value: removeFormat({ name, value }),
      index,
      updates: {overrides: coverageOverrides}
    })
  }

  function onTabDelete () {
    onDeleteTab(index);
  }

  function onPerStatuteRadioUpdate (event) {
    
    delete coverageOverrides[COVERAGE_PROPERTIES.PER_STATUTE.label];
    onUpdate({
      id: coverageRequirement.id,
      name: "limitPerStatute",
      value: event.target.value === COVERAGE_PROPERTIES.PER_STATUTE.label ? event.target.value : "",
      index,
      updates: { overrides: coverageOverrides }
    })

  }

  function onAggregateAppliesUpdate (event) {
    const { aggregateAppliesPer = [] } = coverageRequirement
    const aggregateAppliesPerSet = new Set(aggregateAppliesPer)
    const otherValue = aggregateAppliesPer?.find(
      oneApplies => !coverageTypeConfig.aggregateAppliesPer?.includes(oneApplies)
    )
    if (event.target.name === "OTHER") {
      if (event.target.checked) {
        aggregateAppliesPerSet.add("")
      } else {
        aggregateAppliesPerSet.delete(otherValue)
      }
    } else if (event.target.name === "OTHER_VALUE") {
      aggregateAppliesPerSet.delete(otherValue)
      aggregateAppliesPerSet.add(event.target.value)
      delete coverageOverrides["OTHER"];
    } else {
      if (event.target.checked) {
        aggregateAppliesPerSet.add(event.target.name)
      } else {
        aggregateAppliesPerSet.delete(event.target.name)
      }
    }
    
    delete coverageOverrides[event.target.name];

    onUpdate({
      id: coverageRequirement.id,
      name: "aggregateAppliesPer",
      value: Array.from(aggregateAppliesPerSet),
      index,
      updates: {overrides: coverageOverrides}
    })
  }

  function onCoveredPropertyUpdate (event) {
    const { coveredProperty = [] } = coverageRequirement
    const coveredPropertySet = new Set(coveredProperty)
    const otherValue = coveredProperty?.find(
      oneProperty => !coverageTypeConfig.coveredProperty?.includes(oneProperty)
    )
    if (event.target.name === "OTHER") {
      if (event.target.checked) {
        coveredPropertySet.add("")
      } else {
        coveredPropertySet.delete(otherValue)
      }
    } else if (event.target.name === "OTHER_VALUE") {
      coveredPropertySet.delete(otherValue)
      coveredPropertySet.add(event.target.value)
      delete coverageOverrides["OTHER"];
    } else {
      if (event.target.checked) {
        coveredPropertySet.add(event.target.name)
      } else {
        coveredPropertySet.delete(event.target.name)
      }
    }

    
    delete coverageOverrides[event.target.name];

    onUpdate({
      id: coverageRequirement.id,
      name: "coveredProperty",
      value: Array.from(coveredPropertySet),
      index,
      updates: {overrides: coverageOverrides}
    })
  }

  function onLimitsUpdate (limit, oldName) {
    const limitName = Object.keys(limit)[0];
    if (oldName) {
      delete limits[oldName]
    }
    
    delete coverageOverrides[limitName]

    onUpdate({
      id: coverageRequirement.id,
      name: "limits",
      value: {
        ...limits,
        ...limit
      },
      index,
      updates: { overrides: coverageOverrides }
    })
  }

  function onDateUpdate (name, value, dateIndex) {
    let selectedDate = value;
    const otherDateName = (name === 'policyEff') ? "policyExp" : "policyEff"
    const dateArray = [...(coverageRequirement[name] || [])]
    const otherDateArray = [...(coverageRequirement[otherDateName] || [])]
    
    if (typeof value === "string") {
      selectedDate = new Date(value);
    }
    dateArray[dateIndex] = selectedDate ? usToAWSDate(selectedDate.toLocaleDateString("en-US")) : selectedDate
    if (!otherDateArray[dateIndex]) {
      otherDateArray[dateIndex] = undefined
    } else if (dateIndex === dateArray.length - 1) {
      // dateArray.push(undefined)
      // otherDateArray.push(undefined)
    }
    onUpdate({
      id: coverageRequirement.id,
      index,
      updates: {
        [name]: dateArray,
        [otherDateName]: otherDateArray,
      }
    })
  }

  function onSelectedCoverageUpdate (coverageTypeName, selected, coverageTypeIndex) {
    let selectedCoverage = [...(coverageRequirement.selectedCoverage || [])]
    if (selectedCoverage.indexOf(coverageTypeConfig.displayName) === -1 && !!selectedCoverage[0]) {
      selectedCoverage = ["", ...selectedCoverage]
    }
    selectedCoverage[coverageTypeIndex] = selected ? coverageTypeName : "";
    onUpdate({
      id: coverageRequirement.id,
      index,
      name: "selectedCoverage",
      value: selectedCoverage,
    })
  }

  function onInsuranceFormUpdate (event) {
    const insuranceForm = event.target.name;
    const checked = event.target.checked;
    const insuranceFormsUnique = new Set(coverageRequirement?.insuranceForm)
    insuranceFormsUnique[checked ? "add" : "delete"](insuranceForm)

    
    delete coverageOverrides[event.target.name];

    onUpdate({
      id: coverageRequirement.id,
      index,
      name: "insuranceForm",
      value: Array.from(insuranceFormsUnique),
      updates: {overrides: coverageOverrides}
    })
  }

  const InsuranceFormSection = () => {
    if (!coverageTypeConfig.insuranceForm) {
      return null;
    }
    const claimsMadeError = !coverageRequirement?.insuranceForm?.includes(COVERAGE_PROPERTIES.CLAIMS_MADE.label) &&
      referenceValues?.insuranceForm?.includes(COVERAGE_PROPERTIES.CLAIMS_MADE.label);
    
    if (claimsMadeError & !coverageOverrides[COVERAGE_PROPERTIES.CLAIMS_MADE.label]) {
      coverageOverrides[COVERAGE_PROPERTIES.CLAIMS_MADE.label] = false;
    }
    const occurError = !coverageRequirement?.insuranceForm?.includes(COVERAGE_PROPERTIES.OCCUR.label) &&
      referenceValues?.insuranceForm?.includes(COVERAGE_PROPERTIES.OCCUR.label);
    
    if (occurError & !coverageOverrides[COVERAGE_PROPERTIES.OCCUR.label]) {
      coverageOverrides[COVERAGE_PROPERTIES.OCCUR.label] = false;
    }
    return (
      <Box sx={{ ml: 4 }}>
        <Stack direction="row">
          <FormControl>
            <FormControlLabel
              key={coverageRequirement?.insuranceForm?.includes(COVERAGE_PROPERTIES.CLAIMS_MADE.label) ? "CLAIMS_MADE_checked" : "CLAIMS_MADE_unchecked"}
              checked={coverageRequirement?.insuranceForm?.includes(COVERAGE_PROPERTIES.CLAIMS_MADE.label)}
              name={COVERAGE_PROPERTIES.CLAIMS_MADE.label}
              control={<Checkbox />}
              onChange={onInsuranceFormUpdate}
              label="CLAIMS-MADE"
              className={claimsMadeError && !coverageOverrides[COVERAGE_PROPERTIES.CLAIMS_MADE.label] ? "coverages-validation-error" : null}
              disabled={!isEdit}
            />
          </FormControl>
          <FormControl>
            <FormControlLabel
              key={coverageRequirement?.insuranceForm?.includes(COVERAGE_PROPERTIES.OCCUR.label) ? "OCCUR_checked" : "OCCUR_unchecked"}
              checked={coverageRequirement?.insuranceForm?.includes(COVERAGE_PROPERTIES.OCCUR.label)}
              name={COVERAGE_PROPERTIES.OCCUR.label}
              control={<Checkbox />}
              onChange={onInsuranceFormUpdate}
              label={COVERAGE_PROPERTIES.OCCUR.label}
              className={occurError && !coverageOverrides[COVERAGE_PROPERTIES.OCCUR.label] ? "coverages-validation-error" : null}
              disabled={!isEdit}
            />
          </FormControl>
        </Stack>
      </Box>
    )
  }
  const AdditionAndSubrogationSection = () => {
    const additionalInsuredError = !coverageRequirement?.additionalInsured &&
      referenceValues?.additionalInsured;
    const subrogationWaiverError = !coverageRequirement?.subrogationWaiver &&
      referenceValues?.subrogationWaiver;
    
    if (coverageTypeConfig.additionalInsured && additionalInsuredError && !coverageOverrides[COVERAGE_PROPERTIES.additionalInsured.label]) {
      coverageOverrides[COVERAGE_PROPERTIES.additionalInsured.label] = false;
    }
    
    if (coverageTypeConfig.subrogationWaiver && subrogationWaiverError && !coverageOverrides[COVERAGE_PROPERTIES.subrogationWaiver.label]) {
      coverageOverrides[COVERAGE_PROPERTIES.subrogationWaiver.label] = false;
    }
    
    return (
      <Box sx={{ textAlign: "right" }}>
        {
          coverageTypeConfig.additionalInsured &&
          <FormControl>
            <FormControlLabel
              key={COVERAGE_PROPERTIES.additionalInsured.name + (coverageRequirement?.additionalInsured ? "checked" : "unchecked")}
              control={<Checkbox onClick={onFieldUpdate} name={COVERAGE_PROPERTIES.additionalInsured.name} />}
              checked={coverageRequirement?.additionalInsured}
              disabled={!isEdit}
              label={COVERAGE_PROPERTIES.additionalInsured.label}
              className={
                additionalInsuredError && !coverageOverrides[COVERAGE_PROPERTIES.additionalInsured.label] ? "coverages-validation-error" : null
              }
            />
          </FormControl>
        }
        {
          coverageTypeConfig.subrogationWaiver &&
          <FormControl>
            <FormControlLabel
              key={COVERAGE_PROPERTIES.subrogationWaiver.name + (coverageRequirement?.subrogationWaiver ? "checked" : "unchecked")}
              control={<Checkbox onClick={onFieldUpdate} name={COVERAGE_PROPERTIES.subrogationWaiver.name} />}
              label={COVERAGE_PROPERTIES.subrogationWaiver.label}
              checked={coverageRequirement?.subrogationWaiver}
              disabled={!isEdit}
              className={
                subrogationWaiverError && !coverageOverrides[COVERAGE_PROPERTIES.subrogationWaiver.label] ? "coverages-validation-error" : null
              }
            />
          </FormControl>
        }
      </Box>
    )
  }
  const LimitsSection = () => {
    const perStatuteError =
    !!coverageRequirement?.limitPerStatute &&
    ((referenceValues?.limitPerStatute !== COVERAGE_PROPERTIES.PER_STATUTE.label &&
      (parseFloat(referenceValues.limitPerStatute) > parseFloat(coverageRequirement?.limitPerStatute) ||
        coverageRequirement?.limitPerStatute?.trim().length == 0 ||
        isNaN(coverageRequirement.limitPerStatute))) ||
      (referenceValues?.limitPerStatute === COVERAGE_PROPERTIES.PER_STATUTE.label && referenceValues?.limitPerStatute !== coverageRequirement.limitPerStatute));
    if (perStatuteError && !coverageOverrides[COVERAGE_PROPERTIES.PER_STATUTE.label]) {
      coverageOverrides[COVERAGE_PROPERTIES.PER_STATUTE.label] = false;
    }
    return (
    coverageTypeConfig.limits?.length &&
    <Box sx={{ flexBasis: "50%" }}>
      <SubtitleInline>LIMITS</SubtitleInline>
      {
        !!coverageTypeConfig.limitPerStatute &&
        <Box sx={{ mb: 2 }}>
          {
            !isEdit &&
            <div>{coverageRequirement.limitPerStatute}</div>
          }
          {
            isEdit &&
            <FormControl>
              <RadioGroup
                row
                name="limitPerStatute"
                onChange={onPerStatuteRadioUpdate}
              >
                <FormControlLabel
                  value={COVERAGE_PROPERTIES.PER_STATUTE.label}
                  control={<Radio />}
                  label={COVERAGE_PROPERTIES.PER_STATUTE.label}
                  checked={coverageRequirement?.limitPerStatute === COVERAGE_PROPERTIES.PER_STATUTE.label}
                />
                <FormControlLabel
                  value=""
                  control={<Radio />}
                  label="OTHER"
                  checked={
                    coverageRequirement?.limitPerStatute === "" ||
                    (coverageRequirement?.limitPerStatute && coverageRequirement?.limitPerStatute !== COVERAGE_PROPERTIES.PER_STATUTE.label)
                  }
                />
                <TextField
                  variant={"standard"}
                  name="limitPerStatute"
                  defaultValue={
                    formatCurrencyInt(
                      limits[Object.keys(limits).find(key => key.includes(COVERAGE_PROPERTIES.PER_STATUTE.label))] ||
                      (
                        coverageRequirement?.limitPerStatute === "" ||
                          (coverageRequirement?.limitPerStatute && coverageRequirement?.limitPerStatute !== COVERAGE_PROPERTIES.PER_STATUTE.label)
                          ? coverageRequirement.limitPerStatute
                          : ""
                      )
                    )
                  }
                  disabled={coverageRequirement?.limitPerStatute === COVERAGE_PROPERTIES.PER_STATUTE.label}
                  onBlur={onFieldUpdate}
                />
              </RadioGroup>
              {
                perStatuteError && !coverageOverrides[COVERAGE_PROPERTIES.PER_STATUTE.label] &&
                <FormHelperText error={true}>
                  {
                    referenceValues?.limitPerStatute === COVERAGE_PROPERTIES.PER_STATUTE.label ? "PER STATUTE" : `Required: ${formatCurrencyInt(referenceValues?.limitPerStatute)}`
                  }
                </FormHelperText>
              }

            </FormControl>
          }
        </Box>
      }
      {
        coverageTypeConfig.limits.map(limit => {
          const hasError = referenceLimits[limit] && (parseFloat(referenceLimits[limit]) > parseFloat((limits[limit] || 0)));
          if (hasError && !coverageOverrides[limit]) {
            coverageOverrides[limit] = false;
          }
          return (
            <Box key={"lineItem" + limit}>
              {
                isEdit &&
                <CoverageLineItemEdit
                  name={limit}
                  amount={limits[limit] || 0}
                  onUpdate={onLimitsUpdate}
                  clientAmount={referenceLimits[limit]}
                  clientMode={clientMode}
                  helperTextEntity="Required"
                  showHelperText={hasError && !coverageOverrides[limit]}
                  showHelperTextAsError={true}
                />
              }
              {
                !isEdit &&
                <CoverageLineItem
                  name={limit}
                  amount={limits[limit] || 0}
                />
              }

            </Box>
          )
        })}
      {
        Object.keys(limits).filter(limit => !coverageTypeConfig.limits.includes(limit) && ![COVERAGE_PROPERTIES.PER_STATUTE.label].some(el => limit.includes(el))).map(limit => {
          const hasError = referenceLimits[limit] && (parseFloat(referenceLimits[limit]) > parseFloat((limits[limit] || 0)));
          if (hasError) {
            coverageOverrides[limit] = false;
          }
          return (
            <Box key={"lineItem" + limit}>
              {
                isEdit &&
                <CoverageLineItemEdit
                  name={limit}
                  amount={limits[limit] || 0}
                  onUpdate={onLimitsUpdate}
                  clientAmount={referenceLimits[limit]}
                  clientMode={clientMode}
                  helperTextEntity="Required"
                  showHelperText={hasError && !coverageOverrides[limit]}
                  showHelperTextAsError={true}
                  isNameEditable={true}
                />
              }
              {
                !isEdit &&
                <CoverageLineItem
                  name={limit}
                  amount={limits[limit] || 0}
                />
              }

            </Box>
          )
        })
      }
    </Box>
  )
}
  const InsurerLetterSelection = () => {
    const insurerLetterArray = coverageRequirement?.insurerLetter?.length ? coverageRequirement?.insurerLetter : [""]
    return (
      <Stack direction="column" spacing={1}>
        <SubtitleInline sx={{whiteSpace: "nowrap"}}>INSR LTR</SubtitleInline>
        {
          insurerLetterArray.map((insurerLetter, letterIndex) => {
            if (
              letterIndex > 0 &&
              Array.isArray(coverageRequirement?.policyEff) &&
              !coverageRequirement.policyEff[letterIndex] &&
              Array.isArray(coverageRequirement?.policyExp) &&
              !coverageRequirement.policyExp[letterIndex]
            ) {
              return null;
            }
            return (
              <Select
                key={"insurerLetter" + letterIndex}
                size="small"
                name={"insurerLetter"}
                value={insurerLetter}
                variant="outlined"
                sx={{ width: "fit-content" }}
                onChange={(event) => onInsurerLetterUpdate(event.target.value, letterIndex)}
                disabled={!isEdit}
              >
                {
                  ["", "A", "B", "C", "D", "E", "F"].map(insurerLetter =>
                    <MenuItem value={insurerLetter} children={insurerLetter} key={"insurerLetterOption" + letterIndex + insurerLetter}>
                      {insurerLetter || "-"}
                    </MenuItem>
                  )
                }
              </Select>
            )
          })
        }
      </Stack>
    )
  }

  return (
    <Box sx={{ minHeight: "307px", display: "flex", flexDirection: "column" }}>
      <Stack >
        <CertificateCoverageOverrideModal
          title={"Override coverages"}
          overrides={coverageOverrides}
          open={displayOverrideSection}
          onClose={onCancelOverride}
          onOverride={onCoverageOverride}
          />
        </Stack>
      <Stack mb={4}>
        {
        isEdit && !displayOverrideSection &&
        <Button startIcon={<Warning />} sx={{alignSelf: "end"}} onClick={onOverride} disabled={numberOfCoverageOverrides < 1 }>
          OVERRIDE
        </Button>
        }
        <LimitsSection />
      </Stack>
      <Divider />
      <Stack direction="row" spacing={1} mt={2} mb={2}>
        <Box sx={{flexBasis: "50%"}}>
          <Stack sx={{ml: coverageTypeConfig.selectableCoverage ? "-10px" : "0"}}>
            <Typography variant="body1" sx={{display: "flex", alignItems: "center", fontWeight: 600}}>
              {
                coverageTypeConfig.selectableCoverage &&
                coverageTypeConfig.selectableCoverage[0] === coverageTypeConfig.displayName &&
                <Checkbox
                  key={coverageTypeConfig.displayName + (!!coverageRequirement.selectedCoverage?.includes(coverageTypeConfig.displayName) ? "checked" : "unchecked")}
                  checked={
                     !!coverageRequirement.selectedCoverage?.includes(coverageTypeConfig.displayName)
                  }
                  disabled={!isEdit}
                  onChange={(event) => onSelectedCoverageUpdate(coverageTypeConfig.displayName, event.target.checked, 0)}
                />
              }
              {
                !isCustom && coverageTypeConfig.displayName
              }
              {
                isCustom && (
                  !isEdit
                    ? coverageRequirement.name
                    : <TextField
                        fullWidth={true}
                        label={"TYPE OF INSURANCE"}
                        variant={"standard"}
                        name={"name"}
                        defaultValue={coverageRequirement.name}
                        onBlur={onFieldUpdate}
                      />
                )
              }
            </Typography>
          </Stack>
          <InsuranceFormSection />
          {
            coverageTypeConfig.selectableCoverage &&
            coverageTypeConfig.selectableCoverage.map((selectableCoverage, selectableCoverageIndex) => {
              let indexAdjustedSelectedCoverage = [...(coverageRequirement.selectedCoverage || [])]
              const displayNameIndex = indexAdjustedSelectedCoverage.indexOf(coverageTypeConfig.displayName)
              if(displayNameIndex === -1 && !!indexAdjustedSelectedCoverage[0]) {
                indexAdjustedSelectedCoverage = [coverageTypeConfig.displayName, ...indexAdjustedSelectedCoverage]
              }
              const checked = !!(indexAdjustedSelectedCoverage && indexAdjustedSelectedCoverage[selectableCoverageIndex])

              return (
                selectableCoverage !== coverageTypeConfig.displayName &&
                <FormControl
                  sx={{flexDirection: "column", alignItems: "flex-start"}}
                  key={"selectableCoverage"+selectableCoverageIndex}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={!isEdit}
                        key={"selectedCoverage" + selectableCoverageIndex + (checked ? "checked" : "unchecked")}
                        checked={checked}
                        onChange={
                          event => {
                            if(selectableCoverage === 'CUSTOM') {
                              !event.target.checked && onSelectedCoverageUpdate("", false, selectableCoverageIndex)
                            } else {
                              onSelectedCoverageUpdate(selectableCoverage, event.target.checked, selectableCoverageIndex)
                            }
                          }
                        }
                      />
                    }
                    label={
                      selectableCoverage !== 'CUSTOM'
                        ? <Typography variant="body1" sx={{fontWeight: 600}}>{selectableCoverage}</Typography>
                        : <TextField
                          disabled={!isEdit}
                          onBlur={(event) => onSelectedCoverageUpdate(event.target.value, true, selectableCoverageIndex)}
                          defaultValue={indexAdjustedSelectedCoverage[selectableCoverageIndex] || ""}
                          variant={"standard"}
                          key={
                            "selectableCoverage"
                            + selectableCoverageIndex
                            + `${indexAdjustedSelectedCoverage[selectableCoverageIndex] ? "filled" : "empty"}`
                          }
                        />
                    }
                  />
                </FormControl>
              )
            })
          }
          {
            (coverageTypeConfig.aggregateAppliesPer || coverageTypeConfig.coveredProperty) &&
            <Stack direction="row" spacing={2} sx={{mb:2, mt: 2}}>
              {
                coverageTypeConfig.aggregateAppliesPer &&
                <Box sx={{flexBasis: "50%", flexGrow: 1, mr: 2}}>
                  <SubtitleInline>GEN'L AGGREGATE LIMIT APPLIES PER</SubtitleInline>
                  {
                    <FormGroup row>
                      {
                        coverageTypeConfig.aggregateAppliesPer.map((aggregateType, aggregateTypeIndex) => {
                          const otherValue = coverageRequirement.aggregateAppliesPer?.find(
                            oneType => !coverageTypeConfig.aggregateAppliesPer.includes(oneType)
                          )
                          const otherRequiredValues = referenceValues?.aggregateAppliesPer?.filter(
                            aggregateType => !coverageTypeConfig.aggregateAppliesPer?.includes(aggregateType)
                          )
                          const checked = aggregateType !== "OTHER"
                            ? coverageRequirement.aggregateAppliesPer?.includes(aggregateType)
                            : (otherValue === "" || !!otherValue)
                          
                          const coverageError = 
                            (
                              aggregateType !== "OTHER" &&
                              !checked &&
                              referenceValues?.aggregateAppliesPer?.includes(aggregateType)
                            ) ||
                            (
                              aggregateType === "OTHER" &&
                              !!otherRequiredValues?.length &&
                              parseFloat(otherRequiredValues[0]) > (parseFloat(otherValue) || 0)
                            );
                          
                          if (coverageError && !coverageOverrides[aggregateType]) {
                            coverageOverrides[aggregateType] = false;
                          }

                          return (
                            <Box sx={{display: "flex"}} key={"aggregates-"+aggregateType}>
                              <FormControl sx={{flexDirection: "column", alignItems: "flex-start"}}>
                                <FormControlLabel
                                  key={aggregateType + aggregateTypeIndex + (checked ? "checked" : "unchecked")}
                                  disabled={!isEdit}
                                  control={
                                    <Checkbox
                                      checked={checked}
                                      onChange={onAggregateAppliesUpdate}
                                      name={aggregateType}
                                    />
                                  }
                                  label={aggregateType}
                                  className={
                                    !coverageOverrides[aggregateType] && coverageError ?
                                    "coverages-validation-error" : null
                                  }
                                  />
                                  {
                                    aggregateType === "OTHER" && !coverageOverrides["OTHER"] &&
                                    !!otherRequiredValues?.length &&
                                    parseFloat(otherRequiredValues[0]) > (parseFloat(otherValue) || 0)&&
                                    <FormHelperText className="coverages-validation-error" error={true}>Required: {otherRequiredValues.join(", ")}</FormHelperText>
                                  }
                              </FormControl>
                              {
                                aggregateType === "OTHER" && checked &&
                                <TextField
                                  name="OTHER_VALUE"
                                  onBlur={onAggregateAppliesUpdate}
                                  defaultValue={otherValue || ""}
                                  variant={"standard"}
                                  className={
                                    (
                                      aggregateType !== "OTHER" && !coverageOverrides["OTHER"] &&
                                      !checked &&
                                      referenceValues?.aggregateAppliesPer?.includes(aggregateType)
                                    ) || 
                                    (
                                      aggregateType === "OTHER" && !coverageOverrides["OTHER"] &&
                                      !!otherRequiredValues?.length &&
                                      parseFloat(otherRequiredValues[0]) > parseFloat(otherValue)
                                      ) ?
                                      "coverages-validation-error" : null
                                    }
                                    />
                                  }
                            </Box>
                          )
                        })
                      }
                    </FormGroup>
                  }
                </Box>
              }
              {
                coverageTypeConfig.coveredProperty &&
                <Box sx={{flexBasis: "50%", flexGrow: 1, mr: 2}}>
                  {
                    <FormGroup>
                      {
                        coverageTypeConfig.coveredProperty.map((propertyType, propertyTypeIndex) => {
                          const otherValue = coverageRequirement.coveredProperty?.find(
                            oneType => !coverageTypeConfig.coveredProperty.includes(oneType)
                          )
                          const otherRequiredValues = referenceValues?.coveredProperty?.filter(
                            oneType => !coverageTypeConfig.coveredProperty?.includes(oneType)
                          )
                          const checked = propertyType !== "OTHER"
                            ? coverageRequirement.coveredProperty?.includes(propertyType)
                            : (otherValue === "" || !!otherValue)
                          
                          const coveredPropertyError = (propertyType !== "OTHER" &&
                            !checked &&
                            referenceValues?.coveredProperty?.includes(propertyType)) ||
                            (propertyType === "OTHER" &&
                              otherRequiredValues?.length &&
                              parseFloat(otherRequiredValues[0]) > (parseFloat(otherValue) || 0));
                          
                          if (coveredPropertyError && !coverageOverrides[propertyType]) {
                            coverageOverrides[propertyType] = false;
                          }

                          return (
                            <Box sx={{display: "flex", flexBasis: "50%", flexGrow: 1}} key={"coveredProperty-"+propertyType}>
                              <FormControl sx={{flexDirection: "column", alignItems: "flex-start"}}>
                                <FormControlLabel
                                  disabled={!isEdit}
                                  control={
                                    <Checkbox
                                      key={propertyType + propertyTypeIndex + (checked ? "checked" : "unchecked")}
                                      checked={checked}
                                      onChange={onCoveredPropertyUpdate}
                                      name={propertyType}
                                    />
                                  }
                                  label={propertyType}
                                  className={
                                    coveredPropertyError && !coverageOverrides[propertyType] ? "coverages-validation-error" : null
                                  }
                                  />
                                  {
                                    propertyType === "OTHER" && !coverageOverrides[propertyType] &&
                                    otherRequiredValues?.length &&
                                    parseFloat(otherRequiredValues[0]) > (parseFloat(otherValue) || 0) &&
                                    <FormHelperText error={true}>Required: {otherRequiredValues.join(", ")}</FormHelperText>
                                  }
                              </FormControl>
                              {
                                propertyType === "OTHER" && checked &&
                                <TextField
                                name="OTHER_VALUE"
                                onBlur={onCoveredPropertyUpdate}
                                defaultValue={otherValue || ""}
                                  variant={"standard"}
                                  className={
                                    propertyType === "OTHER" &&
                                    otherRequiredValues?.length &&
                                    parseFloat(otherRequiredValues[0]) > (parseFloat(otherValue) || 0) ?
                                    "coverages-validation-error" : null
                                  }
                                />
                              }
                            </Box>
                          )
                        })
                      }
                    </FormGroup>
                  }
                </Box>
              }
            </Stack>
          }
          {
            (coverageTypeConfig.deductible || coverageTypeConfig.retention || coverageTypeConfig.exclusion) &&
            <Stack direction="row" spacing={2} mb={2} mt={2}>
              {
                (coverageTypeConfig.deductible || coverageTypeConfig.retention) &&
                <Box sx={{flexBasis: "50%", flexGrow: 1, mr: 2}}>
                  <Stack direction="row" spacing={2}>
                    {
                      coverageTypeConfig.deductible &&
                      <Box sx={{flexBasis: "50%", flexGrow: 1, mr: 2}}>
                        <SubtitleInline>DED</SubtitleInline>
                        {
                          <TextField
                            disabled={!isEdit}
                            InputProps={{
                              startAdornment: (<InputAdornment position={"start"}>$</InputAdornment>)
                            }}
                            variant={"standard"}
                            value={formatCurrencyInt(coverageRequirement?.deductible)}
                            name={"deductible"}
                            onChange={onFieldUpdate}
                            helperText={
                              !!referenceValues.deductible &&
                              (!coverageRequirement?.deductible || referenceValues.deductible > coverageRequirement.deductible) &&
                              <FormHelperText error={true}>{`Required: $${formatCurrencyInt(referenceValues.deductible)}`}</FormHelperText>
                            }
                          />
                        }
                      </Box>
                    }
                    {
                      coverageTypeConfig.retention &&
                      <Box>
                        <SubtitleInline>RETENTION</SubtitleInline>
                        {
                          <TextField
                            disabled={!isEdit}
                            InputProps={{
                              startAdornment: (<InputAdornment position={"start"}>$</InputAdornment>)
                            }}
                            variant={"standard"}
                            value={formatCurrencyInt(coverageRequirement?.retention)}
                            name={"retention"}
                            onChange={onFieldUpdate}
                            helperText={
                              !!referenceValues.retention &&
                              (!coverageRequirement?.retention || referenceValues.retention > coverageRequirement.retention) &&
                              <FormHelperText error={true}>{`Required: $${formatCurrencyInt(referenceValues.retention)}`}</FormHelperText>
                            }
                          />
                        }
                      </Box>
                    }
                  </Stack>
                </Box>
              }
              {
                coverageTypeConfig.exclusion &&
                <Box sx={{flexBasis: "50%", flexGrow: 1}}>
                  <Typography variant="body1">ANY PROPRIETOR/PARTNER/EXECUTIVE OFFICER/MEMBER EXCLUDED?</Typography>
                  {
                    <FormControl>
                      <RadioGroup
                        row
                        name="exclusion"
                        onChange={onFieldUpdate}
                        value={coverageRequirement.exclusion}
                      >
                        <FormControlLabel disabled={!isEdit} value="true" control={<Radio />} label="Y" />
                        <FormControlLabel disabled={!isEdit} value="false" control={<Radio />} label="N" />
                      </RadioGroup>
                      {
                        referenceValues?.exclusion !== undefined && !coverageOverrides[COVERAGE_PROPERTIES.exclusion.label] &&
                        String(referenceValues?.exclusion) !== String(coverageRequirement?.exclusion) &&
                        <FormHelperText error={true}>
                          Required: { referenceValues.exclusion === 'true' ? " Y" : " N"}
                        </FormHelperText>
                      }
                    </FormControl>
                  }
                </Box>
              }
            </Stack>
          }
        </Box>
        <Box sx={{flexBasis: "50%"}}>
          <AdditionAndSubrogationSection />
        </Box>
      </Stack>
      <Divider />
      <Stack direction="row" spacing={1} mt={2}>
        <InsurerLetterSelection />
        <Stack spacing={1} sx={{flexGrow: 1}}>
          <SubtitleInline>POLICY NUMBER</SubtitleInline>
          <TextField
            disabled={!isEdit}
            name="policyNumber"
            variant={"outlined"}
            defaultValue={coverageRequirement?.policyNumber}
            onBlur={onFieldUpdate}
            size="small"
            fullWidth
          />
        </Stack>
        <Stack spacing={1}>
          <Stack direction="row" spacing={1}>
            <Box sx={{flexBasis: "50%"}} spacing={1}>
              <SubtitleInline sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                POLICY EFF
                <CalendarMonthIcon color="inherit" />
              </SubtitleInline>
            </Box>
            <Box sx={{flexBasis: "50%"}} spacing={1}>
              <SubtitleInline sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                POLICY EXP
                <CalendarMonthIcon color="inherit" />
              </SubtitleInline>
            </Box>
          </Stack>
          {
            [
              ...(coverageRequirement?.policyEff?.length ? coverageRequirement?.policyEff : [""])
            ].map((policyEff, dateIndex) => {
              const policyExp = [...(coverageRequirement?.policyExp || [""])][dateIndex]
              const expired = policyExp && isExpired(policyExp)
              let effError = ""
              if(coverageRequirement?.policyExp && coverageRequirement.policyExp[dateIndex] && !policyEff) {
                effError = "Please select a date"
              } else if(referenceValues.startDate && policyEff && isLaterDate(policyEff, referenceValues.startDate)) {
                effError = "Latest: " + awsDateToUS(referenceValues.startDate)
              }

              let expError = ""
              if(coverageRequirement?.policyEff && coverageRequirement.policyEff[dateIndex] && !policyExp) {
                expError = isEdit ? "Please select a date" : "Cannot be empty"
              } else if(policyExp && isExpired(policyExp)) {
                expError = "Expired"
              } else if (policyExp && !isExpired(policyExp) && isExpiringIn90Days(policyExp)) {
                const daysToExpire = getExpirationDays(policyExp)
                expError = "Expires in " + daysToExpire + (daysToExpire !== 1 ? " days" : " day")
              } else if(referenceValues.endDate && policyExp && isLaterDate(referenceValues.endDate, policyExp)) {
                expError = "Earliest: " + awsDateToUS(referenceValues.endDate)
              }

              return (
                <Stack direction="row" spacing={1} key={"policyEff-"+dateIndex}>
                  <Box sx={{flexBasis: "50%", maxWidth: "7.5em"}} spacing={1}>
                    {
                      isEdit &&
                      <DatePicker
                        key={"policyEff"+dateIndex}
                        customInput={<PolicyEffInput name={"policyEff"} index={dateIndex} error={effError}/>}
                        name={"policyEff"}
                        selected={
                          policyEff
                            ? getDateFromDateString(policyEff)
                            : false
                        }
                        maxDate={
                          (coverageRequirement?.policyExp && coverageRequirement.policyExp[dateIndex])
                            ? getDateFromDateString(coverageRequirement.policyExp[dateIndex])
                            : undefined
                        }
                        onChange={value => onDateUpdate("policyEff", value, dateIndex)}
                      />
                    }
                    {
                      !isEdit &&
                      <TextField
                        variant={"outlined"}
                        size="small"
                        value={awsDateToUS(policyEff)}
                        disabled={true}
                      />
                    }
                    {
                      effError &&
                      <FormHelperText error>{effError}</FormHelperText>
                    }
                  </Box>
                  <Box sx={{flexBasis: "50%", maxWidth: "7.5em"}} spacing={1}>
                    {
                      isEdit &&
                      <DatePicker
                        key={"policyExp"+dateIndex}
                        customInput={<PolicyExpInput index={dateIndex} error={expError}/>}
                        name={"policyExp"}
                        selected={
                          policyExp
                            ? getDateFromDateString(policyExp)
                            : false
                        }
                        minDate={
                          (coverageRequirement?.policyEff && coverageRequirement.policyEff[dateIndex])
                            ? getDateFromDateString(coverageRequirement.policyEff[dateIndex])
                            : undefined
                        }
                        onChange={value => onDateUpdate("policyExp", value, dateIndex)}
                      />
                    }
                    {
                      !isEdit &&
                      <TextField
                        variant={"outlined"}
                        size="small"
                        value={awsDateToUS(policyExp)}
                        disabled={true}
                      />
                    }
                    {
                      expError &&
                      <FormHelperText error>{expError}</FormHelperText>
                    }
                  </Box>
                </Stack>
              )
            })
          }
        </Stack>
      </Stack>
      {
        isCustom && isEdit &&
        <Button
          onClick={onTabDelete}
          color="error"
          sx={{ alignSelf: "flex-end", mt: 2 }}
          startIcon={<DeleteForever />}
        >
          DELETE COVERAGE
        </Button>
      }
    </Box>
  )
}
