import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  IconButton,
  TextField,
  InputAdornment,
  Typography,
  ButtonGroup,
  Stack,
  Box,
  Skeleton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import UploadIcon from "@mui/icons-material/Upload";
import RemoveIcon from "@mui/icons-material/Remove";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Popup, Image, Tab } from "semantic-ui-react";

import UserTable from "components/UserTable";
import UserDetailsModal from "./UserDetailsModal";
import Snackbar from "components/shared/Snackbar";
import { SubtitleInline, SectionTitle } from "components/shared/Typography";
import OrganizationDetailsForm from "components/shared/OrganizationDetailsForm";
import EmailTemplates from "components/EmailTemplates";
import SideMenuContainer from "components/shared/SideMenuContainer";
import ActivityHistoryInfoSection from "./ActivityHistoryInfoSection";
import useOrganizationActivities from "hooks/useOrganizationActivities";
import ClientTypeOrganizationConfiguration from "./ClientTypeOrganizationConfiguration";
import AffiliateTypeOrganizationConfiguration from "./AffiliateTypeOrganizationConfiguration";

import { signUserUp } from "services/auth";
import cognito_groups from "constants/cognito_groups";
import ORGANIZATION_TYPES, { getHighestOrgType, getProjectLabel, getVendorLabel, getTableRowsPerPage } from "constants/organization_types";
import { deleteLogo, updateLogo } from "services/appResources";
import { useSelector, useDispatch } from "react-redux";
import { organizationActions } from "redux/organizationSlice";
import { userState, userActions } from "redux/userSlice";
import { clientActions } from "redux/clientSlice";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "components/shared/Modal";
import LoaderButton from "../shared/LoaderButton";
import { indexOf, lastIndexOf } from "underscore";

// todo: temp, will be determined by the org select and user selection
const defaultCognitoGroup = {
  [ORGANIZATION_TYPES.SYSTEM]: cognito_groups.SystemAdmin,
  [ORGANIZATION_TYPES.AGENCY]: cognito_groups.AgencyAdmin,
  [ORGANIZATION_TYPES.CLIENT]: cognito_groups.ClientAdmin,
};

const adminGroups = [cognito_groups.SystemAdmin, cognito_groups.AgencyAdmin, cognito_groups.ClientAdmin];

const organizationRoles = {
  [ORGANIZATION_TYPES.SYSTEM]: [{ label: "Admin", value: cognito_groups.SystemAdmin }],
  [ORGANIZATION_TYPES.AGENCY]: [{ label: "Admin", value: cognito_groups.AgencyAdmin }],
  [ORGANIZATION_TYPES.CLIENT]: [
    { label: "Admin", value: cognito_groups.ClientAdmin },
    { label: "Viewer", value: cognito_groups.ClientViewer },
  ],
};
const baseMenuItems = [
  { key: "details", label: "Details" },
  { key: "users", label: "Users" },
];
const clientMenuItems = [
  // {key: "requirements", label: "Required Coverages"},
  { key: "templates", label: "Email Templates" },
  { key: "configuration", label: "Configuration" },
];
const affiliateMenuItems = [
  // {key: "requirements", label: "Required Coverages"},
  // { key: "templates", label: "Email Templates" },
  { key: "configuration", label: "Configuration" },
];

const adminMenuItems = [{ key: "activity_history", label: "Activity History" }];

const textFieldStyles = {
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "#000000",
  },
  "& .MuiInputBase-root.Mui-disabled:before": {
    border: 'none',
  },
  "& .MuiInputLabel-root.MuiInputLabel-formControl,&.MuiInputLabel-root.MuiInputLabel-formControl": {
    color: "#000000",
    fontWeight: 600,
    fontSize: "1.15em",
    transform: "translate(1px, -1.5px) scale(0.75)"
  },
}

export default function OrganizationSettings({ organization = {}, setOrganization = () => {}, logo = "", isClient = false, isAffiliate = false}) {
  
  const isClientTypeOrganization = organization.type.join(" ")?.toUpperCase() === ORGANIZATION_TYPES.CLIENT;
  const [editOrganization, setEditOrganization] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [editUserEmail, setEditUserEmail] = useState("");
  const [updateUserLoading, setUpdateUserLoading] = useState(false);
  const [message, setMessage] = useState();
  const [userToDelete, setUserToDelete] = useState();
  const [updatedLogo, setUpdatedLogo] = useState();
  const [updatedOrganizationName, setUpdateOrganizationName] = useState();
  const [updatedVendorLabel, setUpdatedVendorLabel] = useState();
  const [updatedProjectLabel, setUpdatedProjectLabel] = useState();
  const [updatedCertificateHolder, setUpdatedCertificateHolder] = useState();
  const [updatedClientLabel, setUpdatedClientLabel] = useState();
  const [primaryNotificationEmailAddress, setPrimaryNotificationEmailAddress] = useState();
  const [clientDetails, setClientDetails] = useState({
    website: organization.website || "",
    adminName: organization.adminName || "",
    address1: organization.address1 || "",
    address2: organization.address2 || "",
    phone: organization.phone || "",
    adminEmail: organization.adminEmail || "",
    zip: organization.zip || "",
    city: organization.city || "",
    state: organization.state || "",
  });
  const [tableRowsPerPage, setTableRowsPerPage] = useState();
  const [isCheckedPrimaryNotificationEAddress, setIsCheckedPrimaryNotificationEAddress] = useState(
    isClientTypeOrganization && organization.usePrimaryNotificationEmailAddress
  );
  const [updatedUseDefaultProject, setUpdateUseDefaultProject] = useState(isClientTypeOrganization && organization.useDefaultProject);
  const [roles, setRoles] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  // const [updateCoverage, setUpdateCoverage] = useState(false)
  // const [coverageLoading, setCoverageLoading] = useState(false)
  // const [coverages, setCoverages] = useState([])
  // const [coverageErrors, setCoverageErrors] = useState([])
  const [editTemplates, setEditTemplates] = useState(false);
  const [userSearchInput, setUserSearchInput] = useState("");
  const templateRef = useRef();
  const [organizationActivities, addOrganizationActivity] = useOrganizationActivities(organization);

  const dispatch = useDispatch();
  const userStates = useSelector(userState);
  const userGroups = userStates?.userGroups;
  const canUserEdit = !!userGroups?.find((userGroup) => adminGroups.includes(userGroup));
  const users = (organization?.id && userStates.users[organization.id]) || [];
  const [refreshCoverageView, setRefreshCoverageView] = useState(0);

  const menuItems = [
    ...baseMenuItems,
    ...(isClient ? clientMenuItems : []),
    ...(isAffiliate ? affiliateMenuItems : []),
    ...(canUserEdit ? adminMenuItems : []),
  ];
  const location = useLocation();
  const navigate = useNavigate();
  const activeItem = String(location.hash).replace("#/", "");
  useEffect(() => {
    if (!location.hash) {
      navigate(location.pathname + `#/${menuItems[0].key}`);
    }
  }, []);

  useEffect(() => {
    if (organization?.id) {
      dispatch(userActions.fetchOrganizationUsers(organization.id));
      setRoles(organizationRoles[getHighestOrgType(organization.type)]);
    }
    // if(organization?.clientCoverages?.items?.length) {
    //   setCoverages(organization.clientCoverages.items)
    // }
  }, [organization]);

  async function handleAddUserSubmit(user) {
    setUpdateUserLoading(true);
    const result = await dispatch(userActions.getUser(user.email));
    if (result.payload?.success) {
      if (result.payload?.user?.status === "ACTIVE") {
        setMessage({
          severity: "error",
          text: "A user with this email address already exist. Please use a different Email",
        });
      } else {
        const orgType = getHighestOrgType(organization.type);
        const cognitoGroup = user.role || defaultCognitoGroup[orgType];
        await signUserUp({ ...user, id: result.payload?.user?.id }, cognitoGroup, organization.id, successAddingUser);
        addOrganizationActivity({ user_invited: user });
      }
    } else {
      setMessage({
        severity: "error",
        text: "Error connecting to DB",
      });
    }
    setUpdateUserLoading(false);
  }

  function successAddingUser() {
    setMessage({
      severity: "success",
      text: "Successfully sent invitation to user",
    });
    dispatch(userActions.fetchOrganizationUsers(organization.id));
    setShowAddUserModal(false);
  }

  function onViewUser(email) {
    setEditUserEmail(email);
  }

  async function onUserUpdate(action, success) {
    setUpdateUserLoading(true);
    const result = await action();
    setUpdateUserLoading(false);
    if (result?.payload?.success) {
      success && success();
      setMessage({
        severity: "success",
        text: "Successfully updated user",
      });
      setEditUserEmail("");
    } else {
      setMessage({
        severity: "error",
        text: "Unable to update user information",
      });
    }
  }

  function disableUser(user) {
    onUserUpdate(() => dispatch(userActions.disableUser({ user, organizationId: organization.id })));
    addOrganizationActivity({ user_disabled: user });
  }
  function enableUser(user) {
    onUserUpdate(() => dispatch(userActions.enableUser({ user, organizationId: organization.id })));
    addOrganizationActivity({ user_enabled: user });
  }
  function deleteUser(user) {
    setUserToDelete(user);
  }
  function confirmDeleteUser() {
    onUserUpdate(
      () => dispatch(userActions.deleteUser({ user: userToDelete, organizationId: organization.id })),
      () => {
        setUserToDelete(undefined);
        dispatch(userActions.fetchOrganizationUsers(organization.id));
      }
    );
    addOrganizationActivity({ user_deleted: userToDelete });
  }

  // function onCoverageEdit() {
  //   setCoverageErrors(Array(coverages?.length || 0).fill(false))
  //   setUpdateCoverage(true)
  // }
  //
  // function onCancelCoverageUpdate () {
  //   setCoverages(organization.clientCoverages?.items)
  //   setUpdateCoverage(false)
  // }

  // async function onCoverageSave(coverageData) {
  //   setCoverageLoading(true)
  //   const result = await dispatch(clientActions.saveClientCoverage({
  //     coverages: coverageData,
  //     clientID: organization.clientID,
  //     organizationID: organization.id,
  //   }))
  //   setCoverageLoading(false)
  //   if(result?.payload?.success) {
  //     setUpdateCoverage(false)
  //     setRefreshCoverageView(refresh => refresh+1)
  //     setMessage({
  //       severity: "success",
  //       text: "Successfully updated coverage"
  //     })
  //     addOrganizationActivity({clientCoverages: coverageData})
  //   } else {
  //     setMessage({
  //       severity: "error",
  //       text: "Unable to update coverage"
  //     })
  //   }
  // }

  function handleUpdateUserSubmit(user) {
    onUserUpdate(() => {
      const { id, firstName, lastName, role } = user;
      return dispatch(
        userActions.updateUserInformation({
          id,
          email: user.email,
          input: { id, firstName, lastName, role },
          organizationId: organization.id,
        })
      );
    });
  }

  async function handleConfirmUpdateOrganization() {
    if (updatedLogo) {
      try {
        if (updatedLogo.content) {
          const fetched = await fetch(updatedLogo.content);
          const blobbed = await fetched.blob();
          await updateLogo(`${organization.id}.${updatedLogo.extension}`, blobbed, updatedLogo.type);
          updateOrganizationInformation();
        } else {
          const logoType = logo
            ?.substring(logo.lastIndexOf("/logos/" + organization?.id), logo.lastIndexOf("?"))
            .split(".")
            .at(-1);
          await deleteLogo(organization?.id, logoType);
          updateOrganizationInformation();
        }
      } catch (err) {
        handleLogoUpdateFailed(err);
      }
    } else if (
      updatedOrganizationName ||
      updatedVendorLabel ||
      updatedProjectLabel ||
      updatedCertificateHolder ||
      updatedClientLabel ||
      primaryNotificationEmailAddress ||
      isCheckedPrimaryNotificationEAddress !== organization.usePrimaryNotificationEmailAddress ||
      tableRowsPerPage !== organization.tableRowsPerPage ||
      updatedUseDefaultProject !== organization.useDefaultProject ||
      updatedUseDefaultProject !== organization.useDefaultProject ||
      clientDetails.adminName !== organization.adminName ||
      clientDetails.adminEmail !== organization.adminEmail ||
      clientDetails.address1 !== organization.address1 ||
      clientDetails.address2 !== organization.address2 ||
      clientDetails.city !== organization.city ||
      clientDetails.phone !== organization.phone ||
      clientDetails.zip !== organization.zip ||
      clientDetails.state !== organization.state ||
      clientDetails.website !== organization.website
    ) {
      updateOrganizationInformation();
    }
  }

  function handleLogoUpdateFailed(err) {
    setMessage({
      severity: "error",
      text: "Failed to upload new logo",
    });
    setUpdatedLogo(undefined);
  }

  async function updateOrganizationInformation() {
    const input = {
      id: organization.id,
    };
    updatedOrganizationName && (input.name = updatedOrganizationName);
    updatedVendorLabel && (input.vendorLabel = updatedVendorLabel);
    updatedProjectLabel && (input.projectLabel = updatedProjectLabel);
    updatedCertificateHolder && (input.certificateHolder = updatedCertificateHolder);
    updatedClientLabel && (input.clientLabel = updatedClientLabel);
    primaryNotificationEmailAddress && (input.primaryNotificationEmailAddress = primaryNotificationEmailAddress);
    tableRowsPerPage && (input.tableRowsPerPage = tableRowsPerPage);
    updatedUseDefaultProject !== organization.useDefaultProject && (input.useDefaultProject = updatedUseDefaultProject);
    clientDetails.adminName && clientDetails.adminName !== organization.adminName && (input.adminName = clientDetails.adminName);
    clientDetails.adminEmail && clientDetails.adminEmail !== organization.adminEmail && (input.adminEmail = clientDetails.adminEmail);
    clientDetails.phone && clientDetails.phone !== organization.phone && (input.phone = clientDetails.phone);
    clientDetails.website && clientDetails.website !== organization.website && (input.website = clientDetails.website);
    clientDetails.address1 && clientDetails.address1 !== organization.address1 && (input.address1 = clientDetails.address1);
    clientDetails.address2 && clientDetails.address2 !== organization.address2 && (input.address2 = clientDetails.address2);
    clientDetails.city && clientDetails.city !== organization.city && (input.city = clientDetails.city);
    clientDetails.zip && clientDetails.zip !== organization.zip && (input.zip = clientDetails.zip);
    clientDetails.state && clientDetails.state !== organization.state && (input.state = clientDetails.state);
    isCheckedPrimaryNotificationEAddress !== organization.usePrimaryNotificationEmailAddress &&
      (input.usePrimaryNotificationEmailAddress = isCheckedPrimaryNotificationEAddress);
    updatedLogo && (input.logo = updatedLogo?.content ? `${organization.id}.${updatedLogo.extension}` : "");
    const result = await dispatch(organizationActions.updateOrganizationInformation({ input, isClient }));
    if (result?.payload?.success) {
      setMessage({
        severity: "success",
        text: "Successfully updated organization",
      });

      addOrganizationActivity({
        name: updatedOrganizationName,
        vendorLabel: updatedVendorLabel,
        projectLabel: updatedProjectLabel,
        certificateHolder: updatedCertificateHolder,
        clientLabel: updatedClientLabel,
        useDefaultProject: updatedUseDefaultProject,
        logo: input.logo,
        usePrimaryNotificationEmailAddress: isCheckedPrimaryNotificationEAddress,
        primaryNotificationEmailAddress: primaryNotificationEmailAddress,
        tableRowsPerPage: tableRowsPerPage,
        adminName: clientDetails.adminName,
        adminEmail: clientDetails.adminEmail,
        phone: clientDetails.phone,
        city: clientDetails.city,
        state: clientDetails.state,
        website: clientDetails.website,
        zip: clientDetails.zip,
      });
      setEditOrganization(false);
      setOrganization({
        ...organization,
        useDefaultProject: updatedUseDefaultProject,
        vendorLabel: updatedVendorLabel,
        projectLabel: updatedProjectLabel,
        certificateHolder: updatedCertificateHolder,
        clientLabel: updatedClientLabel,
        usePrimaryNotificationEmailAddress: isCheckedPrimaryNotificationEAddress,
        primaryNotificationEmailAddress: primaryNotificationEmailAddress,
        tableRowsPerPage: tableRowsPerPage,
        logo: input.logo,
        adminName: clientDetails.adminName,
        adminEmail: clientDetails.adminEmail,
        phone: clientDetails.phone,
        city: clientDetails.city,
        state: clientDetails.state,
        website: clientDetails.website,
        zip: clientDetails.zip,
      });
    } else {
      setMessage({
        severity: "error",
        text: "Failed to update organization",
      });
    }
  }

  function handleCancelUpdateOrganization() {
    setUpdatedLogo(undefined);
    setUpdateOrganizationName(undefined);
    setUpdatedVendorLabel(undefined);
    setUpdatedProjectLabel(undefined);
    setUpdatedCertificateHolder(undefined);
    setUpdatedClientLabel(undefined);
    setPrimaryNotificationEmailAddress(undefined);
    setIsCheckedPrimaryNotificationEAddress(organization.usePrimaryNotificationEmailAddress);
    setTableRowsPerPage(organization.tableRowsPerPage);
    setUpdateUseDefaultProject(organization.useDefaultProject);
    setEditOrganization(false);
    setClientDetails({
      website: organization.website || "",
      adminName: organization.adminName || "",
      address1: organization.address1 || "",
      address2: organization.address2 || "",
      phone: organization.phone || "",
      adminEmail: organization.adminEmail || "",
      zip: organization.zip || "",
      city: organization.city || "",
      state: organization.state || "",
    });
  }

  function handleLogoUpdate(e) {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (event) {
        setUpdatedLogo({
          content: event.target.result,
          extension: file.name.split(".").pop(),
          type: file.type,
        });
      };
      reader.readAsDataURL(file);
    }
  }

  function removeLogo() {
    setUpdatedLogo({
      content: null,
      extension: "",
      type: null,
    });
  }

  function haveUpdates() {
    return (
      (updatedOrganizationName && updatedOrganizationName !== organization.name) ||
      (updatedVendorLabel && updatedVendorLabel !== organization.vendorLabel) ||
      (updatedProjectLabel && updatedProjectLabel !== organization.projectLabel) ||
      (updatedCertificateHolder && updatedCertificateHolder !== organization.certificateHolder) ||
      (updatedClientLabel && updatedClientLabel !== organization.clientLabel) ||
      updatedUseDefaultProject !== organization.useDefaultProject ||
      isCheckedPrimaryNotificationEAddress !== organization.usePrimaryNotificationEmailAddress ||
      (primaryNotificationEmailAddress && primaryNotificationEmailAddress !== organization.primaryNotificationEmailAddress) ||
      (tableRowsPerPage && tableRowsPerPage !== organization.tableRowsPerPage) ||
      (clientDetails.adminName && clientDetails.adminName !== organization.adminName) ||
      (clientDetails.adminEmail && clientDetails.adminEmail !== organization.adminEmail) ||
      (clientDetails.address1 && clientDetails.address1 !== organization.address1) ||
      (clientDetails.address2 && clientDetails.address2 !== organization.address2) ||
      (clientDetails.city && clientDetails.city !== organization.city) ||
      (clientDetails.phone && clientDetails.phone !== organization.phone) ||
      (clientDetails.zip && clientDetails.zip !== organization.zip) ||
      (clientDetails.state && clientDetails.state !== organization.state) ||
      (clientDetails.website && clientDetails.website !== organization.website) ||
      updatedLogo
    );
  }

  async function handleTemplatesUpdate() {
    let templateData = templateRef?.current?.getTemplateData();
    if (typeof templateData === "object") {
      templateData = JSON.stringify(templateData);
      await dispatch(
        clientActions.updateClientEmailTemplates({
          clientID: organization.clientID,
          organizationID: organization.id,
          templates: templateData,
        })
      );
      addOrganizationActivity({ templates: templateData });
      setOrganization({ ...organization, templates: templateData });
    }
    setEditTemplates(false);
  }

  return (
    <SideMenuContainer items={menuItems}>
      {/* add user */}
      {canUserEdit && (
        <UserDetailsModal
          open={showAddUserModal}
          onClose={() => setShowAddUserModal(false)}
          onSubmit={handleAddUserSubmit}
          loading={updateUserLoading}
          roles={roles}
        />
      )}

      {/* update user */}
      <UserDetailsModal
        open={!!editUserEmail}
        onClose={() => setEditUserEmail("")}
        onSubmit={handleUpdateUserSubmit}
        loading={updateUserLoading}
        user={users.find((user) => user.email === editUserEmail)}
        roles={roles}
      />
      {/* delete user warning modal */}
      <Modal
        open={!!userToDelete}
        title={"Are you sure?"}
        content={"This user will be permanently deleted."}
        actions={
          <Box>
            <Button onClick={() => setUserToDelete(undefined)}>Cancel</Button>
            <LoaderButton variant={"contained"} onClick={() => confirmDeleteUser()} loading={updateUserLoading}>
              Delete
            </LoaderButton>
          </Box>
        }
      />
      {/*<CoverageUpdateModal*/}
      {/*  title={'Update Organization Coverage Requirements'}*/}
      {/*  open={updateCoverage}*/}
      {/*  loading={coverageLoading}*/}
      {/*  onClose={onCancelCoverageUpdate}*/}
      {/*  coverages={coverages}*/}
      {/*  onSubmit={onCoverageSave}*/}
      {/*/>*/}
      <Snackbar message={message} setMessage={setMessage} />
      <Typography variant="h4" component="div" sx={{ mt: 2, mb: 5, color: "rgb(95,95,95)", fontWeight: 600 }}>
        {organization?.name}
      </Typography>
      {activeItem === menuItems[0].key && (
        <Box>
          <SectionTitle sx={{ mb: 2 }}>
            Details
            {editOrganization ? (
              <ButtonGroup sx={{ ml: 2 }}>
                <Button onClick={handleCancelUpdateOrganization}>Cancel</Button>
                <Button disabled={!haveUpdates() || Object.entries(validationErrors).length > 0} onClick={handleConfirmUpdateOrganization}>
                  Save
                </Button>
              </ButtonGroup>
            ) : (
              <Button onClick={() => setEditOrganization(true)}>Edit</Button>
            )}
          </SectionTitle>
          <Stack spacing={3} direction="column">
            <div>
              <SubtitleInline>
                Organization Name
                {editOrganization && ` *`}
              </SubtitleInline>
              {editOrganization ? (
                <TextField
                  fullWidth
                  required
                  error={updatedOrganizationName === ""}
                  helperText={updatedOrganizationName === "" && "Organization name cannot be empty"}
                  variant="standard"
                  defaultValue={organization.name}
                  onChange={(e) => setUpdateOrganizationName(e.target.value)}
                />
              ) : (
                <Typography variant="body1">{organization.name}</Typography>
              )}
            </div>
            <Stack direction="row" spacing={10} sx={{ mb: 2 }}>
              {organization.mode && (
                <div style={{ flexBasis: "15%" }}>
                  <SubtitleInline>Client Mode</SubtitleInline>
                  <Typography variant="body1">{organization.mode}</Typography>
                </div>
              )}
            </Stack>
            <Stack spacing={10} direction="row" alignItems="start">
              <div style={{ flexBasis: "15%" }}>
                <SubtitleInline>
                  Organization Logo
                  {editOrganization && (
                    <>
                      <label htmlFor="upload-logo-input">
                        <input type="file" hidden id={"upload-logo-input"} onChange={handleLogoUpdate} accept="image/png, image/jpeg, image/jpg" />
                        <Button startIcon={<UploadIcon />} component="span">
                          Upload New Logo
                        </Button>
                      </label>
                      {(updatedLogo?.content || (!updatedLogo && logo)) && (
                        <Button startIcon={<RemoveIcon />} component="span" color="error" onClick={removeLogo}>
                          Remove Logo
                        </Button>
                      )}
                    </>
                  )}
                </SubtitleInline>
                {(updatedLogo?.content || logo) ? <Image src={updatedLogo ? updatedLogo?.content : logo} size="small" /> : <Skeleton variant="circular" sx={{marginTop: 2}} animation={false} width={150} height={150}/>}
              </div>
              
            </Stack>
            {isClientTypeOrganization && (
              <Stack sx={{maxWidth: '900px'}} >
                <OrganizationDetailsForm
                  organizationDetails={clientDetails}
                  setOrganizationDetails={setClientDetails}
                  textFieldStyles={textFieldStyles}
                  variant={"standard"}
                  isEditable={editOrganization}
                />
              </Stack>
            )}
          </Stack>
        </Box>
      )}
      {activeItem === menuItems[1].key && (
        <Box>
          <SectionTitle sx={{ mb: 2 }}>
            Users
            <Popup
              position="bottom center"
              trigger={
                <IconButton sx={{ ml: 2 }} onClick={() => setShowAddUserModal(true)}>
                  <AddCircleIcon color="primary" />
                </IconButton>
              }
            >
              Add User
            </Popup>
            <TextField
              value={userSearchInput}
              onChange={(e) => setUserSearchInput(e.target.value)}
              sx={{ float: "right" }}
              size="small"
              placeholder="Search"
              id="fullWidth"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                  </InputAdornment>
                ),
              }}
            />
          </SectionTitle>
          <UserTable
            users={users}
            onViewUser={onViewUser}
            searchPhrase={userSearchInput}
            canEdit={canUserEdit}
            disableUser={disableUser}
            enableUser={enableUser}
            deleteUser={deleteUser}
          />
        </Box>
      )}
      {/*{*/}
      {/*  isClient && (activeItem === menuItems[2].key) &&*/}
      {/*  <Box>*/}
      {/*    /!* Requirements *!/*/}
      {/*    <SectionTitle sx={{mb: 2}}>*/}
      {/*      Default Required Coverages*/}
      {/*      <Button*/}
      {/*        onClick={onCoverageEdit}*/}
      {/*      >*/}
      {/*        Update*/}
      {/*      </Button>*/}
      {/*    </SectionTitle>*/}
      {/*    <CoverageForm isEdit={false} coverages={organization?.clientCoverages?.items} refresh={refreshCoverageView}/>*/}
      {/*  </Box>*/}
      {/*}*/}
      {isClient && activeItem === menuItems[2].key && (
        <Box>
          <SectionTitle sx={{ mb: 2 }}>
            Email Templates
            {!editTemplates && <Button onClick={() => setEditTemplates(true)}>Update</Button>}
            {editTemplates && (
              <ButtonGroup sx={{ ml: 2 }}>
                <Button onClick={() => setEditTemplates(false)}>Cancel</Button>
                <Button onClick={handleTemplatesUpdate} variant={"contained"}>
                  Save
                </Button>
              </ButtonGroup>
            )}
          </SectionTitle>
          <EmailTemplates
            ref={templateRef}
            templates={organization?.templates}
            isEdit={editTemplates}
            clientName={organization?.name}
            vendorLabel={organization?.vendorLabel}
          />
        </Box>
      )}
      {isClient && activeItem === "configuration" && (
        <ClientTypeOrganizationConfiguration
          organization={organization}
          tableRowsPerPage={tableRowsPerPage}
          validationErrors={validationErrors}
          editOrganization={editOrganization}
          isCheckedPrimaryNotificationEAddress={isCheckedPrimaryNotificationEAddress}
          haveUpdates={haveUpdates}
          setEditOrganization={setEditOrganization}
          setValidationErrors={setValidationErrors}
          setTableRowsPerPage={setTableRowsPerPage}
          setUpdatedVendorLabel={setUpdatedVendorLabel}
          setUpdatedProjectLabel={setUpdatedProjectLabel}
          updatedUseDefaultProject={updatedUseDefaultProject}
          setUpdateUseDefaultProject={setUpdateUseDefaultProject}
          handleCancelUpdateOrganization={handleCancelUpdateOrganization}
          handleConfirmUpdateOrganization={handleConfirmUpdateOrganization}
          setPrimaryNotificationEmailAddress={setPrimaryNotificationEmailAddress}
          setIsCheckedPrimaryNotificationEAddress={setIsCheckedPrimaryNotificationEAddress}
        />
      )}
      {isAffiliate && activeItem === "configuration" && (
        <AffiliateTypeOrganizationConfiguration
        organization={organization}
          validationErrors={validationErrors}
          editOrganization={editOrganization}
          haveUpdates={haveUpdates}
          setEditOrganization={setEditOrganization}
          setUpdatedClientLabel={setUpdatedClientLabel}
          setUpdatedCertificateHolder={setUpdatedCertificateHolder}
          handleCancelUpdateOrganization={handleCancelUpdateOrganization}
          handleConfirmUpdateOrganization={handleConfirmUpdateOrganization}
        />
      )}
      {canUserEdit && activeItem === "activity_history" && (
        <Box>
          <ActivityHistoryInfoSection
            {...{
              organizationActivities,
            }}
          />
        </Box>
      )}
    </SideMenuContainer>
  );
}
