/* eslint-disable */

export const getOrganizationUsers = /* GraphQL */ `
    query GetOrganizationUsers($id: ID!) {
        getORGANIZATION(id: $id) {
            id
            name
            themeColor
            logo
            isAgency
            organizationUsers (filter: {status: {ne: DELETED}}) {
                items {
                    createdAt
                    email
                    firstName
                    id
                    lastName
                    organizationID
                    role
                    status
                }
            }
            createdAt
            updatedAt
        }
    }
`;

export const listORGANIZATIONS = /* GraphQL */ `
    query ListORGANIZATIONS(
        $filter: ModelORGANIZATIONFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listORGANIZATIONS(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                name
                vendorLabel
                projectLabel
                clientLabel
                certificateHolder
                useDefaultProject
                usePrimaryNotificationEmailAddress
                primaryNotificationEmailAddress
                tableRowsPerPage
                themeColor
                logo
                isAgency
                type
                createdAt
                updatedAt
            }
            nextToken
        }
    }
`;

export const updateORGANIZATION = /* GraphQL */ `
    mutation UpdateORGANIZATION(
        $input: UpdateORGANIZATIONInput!
        $condition: ModelORGANIZATIONConditionInput
    ) {
        updateORGANIZATION(input: $input, condition: $condition) {
            id
            name
            vendorLabel
            projectLabel
            clientLabel
            certificateHolder
            useDefaultProject
            usePrimaryNotificationEmailAddress
            primaryNotificationEmailAddress
            tableRowsPerPage
            logo
            adminName
            adminEmail
            phone
            address1
            address2
            website
            city
            state
            zip
        }
    }
`;

export const clientByAgencyOrganizationID = /* GraphQL */ `
    query ClientByAgencyOrganizationID(
        $agencyOrganizationID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelCLIENTFilterInput
        $limit: Int
        $nextToken: String
    ) {
        clientByAgencyOrganizationID(
            agencyOrganizationID: $agencyOrganizationID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                organizationID
                agencyOrganizationID
                mode
                templates
                clientOrganization {
                    id
                    logo
                    name
                    vendorLabel
                    projectLabel
                    clientLabel
                    certificateHolder
                    website
                    adminName
                    adminEmail
                    phone
                    address1
                    address2
                    city
                    state
                    zip
                    useDefaultProject
                    usePrimaryNotificationEmailAddress
                    primaryNotificationEmailAddress
                    tableRowsPerPage
                    themeColor
                    type
                }
                clientCoverages {
                    items {
                        id
                        name
                        insuranceType
                        insuranceForm
                        aggregateAppliesPer
                        coveredProperty
                        additionalInsured
                        subrogationWaiver
                        deductible
                        retention
                        exclusion
                        limits
                        limitPerStatute
                        createdAt
                        updatedAt
                    }
                }
            }
            nextToken
        }
    }
`;

export const clientByOrganizationID = /* GraphQL */ `
    query ClientByOrganizationID(
        $organizationID: ID!
        $sortDirection: ModelSortDirection
        $filter: ModelCLIENTFilterInput
        $limit: Int
        $nextToken: String
    ) {
        clientByOrganizationID(
            organizationID: $organizationID
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                organizationID
                agencyOrganizationID
                mode
                templates
                clientOrganization {
                    id
                    logo
                    vendorLabel
                    projectLabel
                    clientLabel
                    certificateHolder
                    website
                    adminName
                    adminEmail
                    phone
                    address1
                    address2
                    city
                    state
                    zip
                    useDefaultProject
                    usePrimaryNotificationEmailAddress
                    primaryNotificationEmailAddress
                    tableRowsPerPage
                    name
                    themeColor
                    type
                }
                clientCoverages {
                    items {
                        id
                        name
                        insuranceType
                        insuranceForm
                        aggregateAppliesPer
                        coveredProperty
                        additionalInsured
                        subrogationWaiver
                        deductible
                        retention
                        exclusion
                        limits
                        createdAt
                        updatedAt
                    }
                }
            }
            nextToken
        }
    }
`

export const createAgencyOrganization = /* GraphQL */ `
    mutation CreateORGANIZATION(
        $name: String
        $themeColor: String
    ) {
        createORGANIZATION(input: {
            name: $name,
            themeColor: $themeColor,
            type: AGENCY
        }) {
            id
            name
            vendorLabel
            projectLabel
            clientLabel
            certificateHolder
            useDefaultProject
            usePrimaryNotificationEmailAddress
            primaryNotificationEmailAddress
            tableRowsPerPage
            themeColor
            logo
            type
        }
    }
`;

export const createClientOrganization = /* GraphQL */ `
    mutation CreateORGANIZATION(
        $name: String
        $adminName: String
        $adminEmail: AWSEmail
        $phone: String
        $address1: String
        $address2: String
        $city: String
        $state: String
        $zip: Int
        $website: String
        $themeColor: String
    ) {
        createORGANIZATION(input: {
            name: $name,
            themeColor: $themeColor,
            adminName: $adminName,
            adminEmail: $adminEmail,
            phone: $phone,
            address1: $address1
            address2: $address2
            city: $city
            state: $state
            zip: $zip
            website: $website
            type: CLIENT
        }) {
            id
            name
            vendorLabel
            projectLabel
            clientLabel
            certificateHolder
            useDefaultProject
            usePrimaryNotificationEmailAddress
            primaryNotificationEmailAddress
            tableRowsPerPage
            themeColor
            logo
            type
            adminName
            adminEmail
            phone
            address1
            address2
            website
            city
            state
            zip
        }
    }
`;

export const createProjectBasedClient = /* GraphQL */ `
    mutation CreateCLIENT(
        $organizationID: ID!
        $agencyOrganizationID: ID!
    ) {
        createCLIENT(input: {
            organizationID: $organizationID,
            agencyOrganizationID: $agencyOrganizationID,
            mode: PROJECT
        }) {
            id
            organizationID
            agencyOrganizationID
            mode
        }
    }
`;

export const createCategoryBasedClient = /* GraphQL */ `
    mutation CreateCLIENT(
        $organizationID: ID!
        $agencyOrganizationID: ID!
    ) {
        createCLIENT(input: {
            organizationID: $organizationID,
            agencyOrganizationID: $agencyOrganizationID,
            mode: CATEGORY
        }) {
            id
            organizationID
            agencyOrganizationID
            mode
        }
    }
`;


